.publish-articles-head{
    font-family: sans-serif;
    font-size: 30px;
    padding-top: 25px;
    color: #313979;
}
.publish-articles-para{
    font-family: sans-serif;
    font-size: 16px;
    padding-top: 15px;
    text-align: center;
}
.article-writing-sm-img{
    height: 100px;
}
.search-engine-friendly-content{
    font-family: sans-serif;
    font-size: 18px;
    padding-top: 15px;
    color: #313979;
    text-align: center;
    line-height: 30px;
}

.publish-article-images-container{
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 250px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.publish-article-images-container:hover{
    margin-top: -15px;
    transition: 0.5s;
    background-color: aliceblue;
}
.publish-article-images-container-main{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
}

.publish-articles-spec-con{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.publish-articles-spec-con-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

@media screen and (max-width:912px){
    .artile-write-img{
        width: 100%;
    }
}

@media screen and (max-width:612px){
    .publish-article-images-container{
        width: 100%;
    }

    .publish-articles-head{
        font-size: 25px;
        line-height: 35px;
        text-align: center;
        margin-right: 0px;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .artile-write-img{
        width: 100%;
    }
}