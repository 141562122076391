.vid-container-backend{
    align-self: flex-start;
    border: none;
}

.backend-technologies-we-use{
    font-family: sans-serif;
    color: #313979;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
    line-height: 60px;
    
}
.backend-technologies-we-use-line{
    border-bottom: 1px solid darkgray;
    width: 86%;
}

.backend-development-img-container{
    height: fit-content;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.new-image-frontend-b{
    width: 50%;
    align-self: flex-start;
}
.backend-development-img-container:hover{
    background-color: #e8f4f8;
}
.backend-img-route{
    height: 85px;
}

.backend-route-head{
    font-family: sans-serif;
    font-size: 23px;
    color: #313979;
    padding-top: 10px;
    border-bottom: 2px solid #2b6c7f;
    border-radius: 40%;
    width: 80%;
    text-align: center;
}

.backend-route-para{
    font-family: sans-serif;
    font-size: 15px;
    text-align: center;
    padding-top: 15px;
}

.backend-technologies-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 40px;
}

.backend-technologies-con-route{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backend-technologies-con-route-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backend-img-route-2{
    height: 55px;
}
.new-image-backend{
    width: 50%;
}

@media screen and (max-width:912px){
    .vid-container-backend{
        align-self: center;
    }
    .backend-technologies-we-use{
        font-size: 23px;
        line-height: 40px;
        text-align: center;
        padding-bottom: 10px;
    }
}

@media screen and (max-width:991px){
    .respon-img-backend{
        width: 100%;
    }
}