.transform-head{
    align-self: flex-start;
      margin-top: 25px;
      font-family: sans-serif;
      color: #313979;
      font-size: 42px;
      font-weight: bold;
      line-height: 48px;
      line-height: 60px;
      padding-bottom: 15px;
  }
  
  .transform-para{
    font-family: sans-serif;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
  }
  .transform-right{
    height: 60px;
  }
  
  .transform-right-list-container-items{
    margin-top: 20px;
  }
  .transform-right-para{
    font-family: sans-serif;
      font-size: 18px;
      padding-left: 15px;
  }
  
  .transform-right-list-container{
    list-style-type: none;
    padding-left: 0px;
  }
  .list-transform{
    display: flex;
    align-items: center;
  }
  .new-image-frontend{
    width: 40%;
  }
  .no-transform-btn{
    color: #ffffff;
    background-color: #1b1b1b;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    font-family: sans-serif;
    font-size: 17px;
    font-weight: bold;
    height: 60px;
  }
  
  .no-transform-btn:hover{
    background-color: #ffffff;
    color: #1b1b1b;
    border: 1px solid #1b1b1b;
  }
  
  .no-transform-btn-2{
    background-color: #ffffff;
    color: blue;
    height: 50px;
    font-size: 17px;
    font-family: sans-serif;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    border: 1px solid blue;
    margin-left: 20px;
    margin-top: 25px;
  }
  .no-transform-btn-2:hover{
    background-color: #1b32b6;
    color: #ffffff;
    cursor: pointer;
  }
  .no-transform-button-container{
    display: flex;
    align-items: center;
  }
  
  .video-at{
    height: 400px;
    border: none;
  }
  .transform-main-cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
  }
  .transform-main-cont-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .transform-img{
    height: 400px;
  }

  .transform-right-para:hover{
    color: rgb(77, 77, 214);
  }

  .transform-para-tech{
    font-family: sans-serif;
    font-size: 18px;
    width: 70%;
  }

  .transform-best-frontend{
    font-family: sans-serif;
    font-size: 33px;
    color: #313979;
    padding-top: 30px;
  }

  .hr-line-transform{
    border: 2px solid darkgray;
    margin-top: 8px;
    width: 90%;
  }
  .tech-use-img-container-transform{
    width: 48%;
    border-radius: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .tech-use-img-trransform{
    height: 140px;
  }
  .tech-use-img-container-transform-head{
    font-family: sans-serif;
    font-size: 20px;
    color: #313979;
  }

  .transform-tech-skills-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 28px;
    padding-bottom: 50px;
    justify-content: center;
  }

  .transform-tech-skills-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .transform-tech-skills-co{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }



  .transform-para-tech-c{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.frontend-route-chg-image{
  width: 40%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 230px;
  border-radius: 10px;
}
.frontend-route-chg-image:hover{
  margin-top: -10px;
  transition: 2s;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
  @media  screen and (max-width:991px) {
    .new-image-frontend{
      width: 90%;
    }
    .transform-head{
      font-size: 26px;
      line-height: 38px;
      text-align: center;
    }
    .transform-para-tech-mobile{
      width: 90%;
    }
    .transform-best-frontend{
      font-size: 26px;
      line-height: 40px;
      text-align: center;
    }
    .tech-use-img-container-transform{
      width: 40%;
      margin: 10px;

    }
    .transform-tech-skills-container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .tech-use-img-trransform{
      height: 100px;
    }
    .tech-use-img-container-transform-head{
      text-align: center;
      line-height: 30px;
      padding-top: 20px;
    }
  }
  @media screen and (max-width:991px){
    .responsive-con-frontend{
      width: 100%;
    }
    .transform-main-cont{
      flex-direction: column;
      width: 90%;
    }
    .new-image-frontend{
      width: 100%;
    }
  }

  @media  screen and (max-width:539px) {
    .tech-use-img-container-transform{
      width: 100%;
    }
    .transform-right-para-m{
      padding-left: 2px;
    }
  }
  .frontend-mobile-n-con{
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;
    width: 40%;
  }
  .frontend-mobile-n-con:hover{
    margin-top: -10px;
    transition: 2s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .new-transform-insu{
    width: 85%;
  }

  