.poster-design-main-container-route{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.tilt-co{
    margin-right: 8px;
    margin-bottom: 13px;
    margin-left: 8px;
    width: 23%;
  }
  
  .poster-des{
    width: 100%;

    flex-shrink: 1;
  }
  .poster-des-span{
    font-size: 17px;
  }
  .poster-des-para{
    width: 920px;
    font-size: 18px;
  }
  .posters-main-con{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
  }


  .click-to-load-more {
    margin-top: 25px;
    width: 200px;
    height: 60px;
    background: linear-gradient(90deg, #0700b8 0%, #7881ce 100%);
    font-size: 17px;
    border-radius: 30px;
    color: white;
    border: none;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 500ms ease;
    box-shadow: 0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -2px hsla(0, 0%, 0%, 0.1);
}

.click-to-load-more:hover {
    background: linear-gradient(90deg, rgb(94, 94, 182), #3743b3 0%, #1e19ad 100%);
}


@media screen and (min-width:541px) and (max-width:767px){
  .mob-web-port-v-con-pp{
    width: 45%;
    margin: 10px;
  }
}

@media screen and (max-width:540px){
  .mob-web-port-v-con-pp{
    width: 90%;
    margin: 10px;
  }
}