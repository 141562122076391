.transform-2-para{
    padding-top: 15px;
    padding-bottom: 10px;
}
.transform-right-2-para{
    max-width: 600px;
    flex-shrink: 1;
}

.transform-2-head{
    max-width: 600px;
}

.word-press-dev-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.word-press-dev-inner-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.our-wordpress-development{
    font-family: sans-serif;
    color: #313979;
    font-size: 35px;
    padding-bottom: 20px;
}

.wordpress-our-serv-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.wordpress-our-serv-con{
    width: 100%;
    margin-right: 15px;
    flex-shrink: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    height: fit-content;

}
.wordpress-our-serv-con:hover{
    background-color: #e8f4f8;
}
.wordPress-new-tech-con{
    width: 45%;
    flex-shrink: 1;
    margin-right: 15px;
    margin-bottom: 15px;
}

.tech-use-img-wordpress{
    height: 110px;
}

.tech-use-img-container-wordpress-head{
    max-width: 300px;
    line-height: 30px;
    text-align: center;
    padding-top: 25px;
}

.wordpress-para-container{
    font-size: 15px;
    line-height: 25px;
}

.wordpress-para-container-main{
    border: 2px solid darkgray;
    padding: 10px;
    margin-top: 20px;
}

@media screen and (max-width:540px){
    .wordPress-new-tech-con{
        width: 100%;
    }
    .our-wordpress-development{
        font-size: 25px;
        line-height: 40px;
        text-align: center;
    }
}

@media screen and (max-width:992px){
    .transform-2-head{
        max-width: 90%;
    }
    .transform-right-2-para{
        max-width: 100%;
    }
}