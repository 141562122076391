

.testmonials-container-web{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    background-image: url("https://png.pngtree.com/thumb_back/fw800/background/20190221/ourmid/pngtree-blue-laser-wind-gradient-fluid-image_22189.jpg");
	padding-bottom: 50px;
	padding-top: 50px;
}
.slider-con{
	width: 90%;
}

.services-testimonials-head{
	font-size: 18px;
	font-family: sans-serif;
    padding-bottom: 10px;
}
.client-testimonals-para{
	font-family: sans-serif;
	font-size: 45px;
	font-weight: bold;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #ffffff;
}

.check-out-what-para{
	font-family: sans-serif;
	font-size: 20px;
	color: #ffffff;
	padding-bottom: 70px;
    padding-top: 30px;
}

.testimonals-con-main{
	background-color: #ffffff;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 25px;
	padding: 20px;
	border-radius: 10px;
	margin-right: 20px;
	flex-shrink: 1;
}
.testimonals-logo-con{
	height: 55px;
}
.testimonals-logo-cont{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 1;
}

.testimonals-card-para{
	font-family: sans-serif;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
	padding-top: 17px;
	padding-bottom: 10px;
}

.testimonals-span-para{
	text-decoration: underline;
	color: blue;
}
.testimonals-star-con{
	height: 40px;
}
.testimonals-name-head{
	font-family: sans-serif;
	font-size: 18px;
	letter-spacing: 0.8px;
}
.testimonals-role-para{
	font-family: sans-serif;
	font-size: 17px;
	padding: 0px;
	margin:0px;
	padding-bottom: 3px;
}
.testimonals-name-div-cont{
	margin-top: 30px;
}
.services-testimonials-head{
	color: #ffffff;
	text-align: center;
	width: 90%;
	line-height: 37px;
}

@media screen and (max-width:767px) {
	.client-testimonals-para{
		font-size: 28px;
		padding-top: 0px;
		margin-top: 0px;
	}
	.services-testimonials-head{
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.check-out-what-para{
		font-size: 17px;
		padding-top: 5px;
		margin-top: 0px;
		text-align: center;
	}
	.testimonals-con-main{
		width: 95%;
		margin: 10px;
	}
	
}