.count-two-head-c{
    font-family: sans-serif;
    font-size: 24px;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    color: #ffffff;
    line-height: 20px !important;
    text-align: center;
}

.count-two-para-c{
    font-size: 12px !important;
    line-height: 10px;
    padding-top: 10px;
}