.annual-maintanance-terms-heading{
    font-family: sans-serif;
    font-size: 35px;
    padding-top: 10px;
}

@media screen and (max-width:600px){
    .annual-maintanance-terms-heading{
        font-size: 25px;
        line-height: 32px;
    }
}