.seo-img-portfolio{
    min-height: 250px;
    width: 70%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705382850/mxf5a7p84d9os9baegy7.png");
}
.seo-img-portfolio-2{
    min-height: 350px;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705386544/z71mnnx5v8ueonkgib4h.png");
}
.seo-img-portfolio-container{
    text-align: center;
    display: none;
    width: 90%;
}
.seo-img-portfolio:hover{
    .seo-img-portfolio-container{
        display: block;
    }
    background-image: none;
    background-color: #020079;
}

.seo-img-portfolio-container-heading{
    font-family: sans-serif;
    font-size: 25px;
    letter-spacing: 2px;
    color: #ffffff;
    font-weight: normal;
    padding-bottom: 20px;
}

