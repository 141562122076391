.video-ads-cont-img{
    width: 40%;
    align-self: center;
}

@media screen and (max-width:912px){
    .video-ads-cont-img-mob{
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .artile-write-img-vv{
        width: 100%;
        margin-top: 15px;
    }
}