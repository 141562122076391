.digital-mar-img-rote{
    width: 45%;
}

.digital-mar-head-name{
    font-family: sans-serif;
    color: #313979;
    font-size: 26px;
    padding-bottom: 20px;
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.digital-mar-head-para{
    font-size: 18px;
    font-family: sans-serif;
    max-width: 1000px;
}
.digital-market-our-ser-img{
    height: 65px;
}
.digital-marget-con-div-main-rote{
    width: 30%;
    flex-shrink: 1;
}

.video-content-img-head{
    font-family: sans-serif;
    color: #313979;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    padding-top: 18px;
    height: 100px;
    border-radius: 10px;
}
.digital-marketing-main-container-ro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    
}
.new-digi-con{
    width: 100% !important;
}

.digital-marketing-main-container-ro-1{
    display: flex;
    flex-direction: column;
    width: 85%;
}
.digital-marketing-main-container-ro-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.digital-mar-head-name-2{
    text-align: center;
    padding-top: 35px;
}


@media screen and (max-width:912px){
    .digital-mar-img-rote-mobile{
        width: 100%;
        margin-top: 30px;
    }
}

@media  screen and (max-width:540px) {
    .digital-mar-head-name{
        font-size: 23px;
        line-height: 37px;
    }
}

@media screen and (max-width:539px){
    .digital-marget-con-div-main-rote{
        width: 100% !important;
        margin: 10px;
    }
}

@media screen and (min-width:540px) and (max-width:767px){
    .digital-marget-con-div-main-rote{
        width: 40% !important;
        margin: 10px;
    }
}


@media screen and (min-width:768px) and (max-width:991px){
    .digital-mar-img-rote-mobile{
        width: 100%;
    }
}