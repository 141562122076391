.graphic-design-route-logos{
    height: 63px;
    border-radius: 10px;
}
.graphic-route-ty-head{
    font-family: sans-serif;
    font-size: 35px;
    color: #313979;
    padding-top: 10px;
    padding-bottom: 10px;
}

.graphic-route-ty-para{
    font-size: 18px;
    width: 800px;
    text-align: center;
}

.graphic-design-ty-iten-head{
    font-size: 20px;
    color: #313979;
    font-family: sans-serif;
    line-height: 25px;
    padding-bottom: 8px;
}

.graphic-design-ty-list-items{
    display: flex;
    width: 45%;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    margin: 15px;
}
.graphic-design-ty-list-items:hover{
    margin-top: -10px;
    background-color: aliceblue;
    transition: 1s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.graphic-design-ty-list-items-div{
    margin-left: 20px;
}
.graphic-route-ty-item-con{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}
.graphic-route-ty-head-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.graphic-route-ty-head-main-container-1{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-w-2{
    width: 30%;
    height: 380px;
}

.logo-design-inner-new-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left !important;
    margin-left: 20px !important;
}

@media screen and (max-width:912px){
    .your-logo-pd-top{
        padding-top: 20px;
    }
}

@media screen and (max-width:767px){
    .logo-design-prev-mob-v-para{
        width: 90%;
        font-size: 18px;
    }
    .logo-design-prev-mob-v{
        width: 90%;
        font-size: 28px;
        text-align: center;
    }
    .mobile-logo-desi-con{
        width: 100%;
        margin: 10px;
    }
    
}

