.footer-area {
    background-color: #080a3c;
    position: relative;
    z-index: 1;
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;

  }
  .footer-cont-mob-v{
    width: 95%;
  }
  .footer-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    animation: imageChange 1s linear 1s infinite;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1704799550/gdybffajvwanaizlyhhq.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .footer-area::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    animation: imageChangeTwo 1s linear 1s infinite;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1704799576/ikuk3tiyikqnvxxsvoji.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .single-footer-widget {
    margin-bottom: 30px;
  }
  .single-footer-widget h3 {
    color: #ffffff;
    margin-bottom: 29px;
    font-weight: 500;
    font-size: 22px;
  }
  .single-footer-widget .logo {
    margin-bottom: 15px;
  }
  .single-footer-widget .logo a {
    display: inline-block;
  }
  .single-footer-widget p {
    color: #acc5db;
  }
  .single-footer-widget .services-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .single-footer-widget .services-list li {
    color: #acc5db;
    margin-bottom: 10px;
  }
  .single-footer-widget .services-list li:last-child {
    margin-bottom: 0;
  }
  .single-footer-widget .services-list li a {
    display: inline-block;
    color: #acc5db;
  }
  .single-footer-widget .services-list li a:hover {
    color: #13c4a1;
    padding-left: 5px;
  }
  .single-footer-widget .support-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .single-footer-widget .support-list li {
    color: #acc5db;
    margin-bottom: 10px;
  }
  .single-footer-widget .support-list li:last-child {
    margin-bottom: 0;
  }
  .single-footer-widget .support-list li a {
    display: inline-block;
    color: #acc5db;
  }
  .single-footer-widget .support-list li a:hover {
    color: #13c4a1;
    padding-left: 5px;
  }
  .single-footer-widget .footer-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .single-footer-widget .footer-contact-info li {
    color: #ffffff;
    margin-bottom: 10px;
  }
  .single-footer-widget .footer-contact-info li:last-child {
    margin-bottom: 0;
  }
  .single-footer-widget .footer-contact-info li a {
    display: inline-block;
    color: #acc5db;
  }
  .single-footer-widget .footer-contact-info li a:hover {
    color: #13c4a1;
  }
  .single-footer-widget .footer-contact-info li span {
    color: #acc5db;
  }
  .single-footer-widget .social {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .single-footer-widget .social li {
    display: inline-block;
    margin-right: 8px;
  }
  .single-footer-widget .social li a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 33px;
    background-color: #4e6e8a;
    text-align: center;
    font-size: 17px;
    border-radius: 2px;
  }
  .single-footer-widget .social li a:hover {
    color: #080a3c;
    background-color: #ffffff;
  }
  
  .copyright-area {
    border-top: 1px solid #1c2250;
    text-align: center;
    margin-top: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .copyright-area p {
    line-height: initial;
    color: #acc5db;
  }
  .copyright-area p a {
    display: inline-block;
    color: #ff612f;
  }
  .copyright-area p a:hover {
    color: #13c4a1;
  }
  .foot{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  @keyframes imageChange {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes imageChangeTwo {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }





  
  .foot-para{
    max-width: 330px;
  }
  .services-li-con-foot{
    padding-bottom: 8px;
  }
  .services-li-con-foot-a{
    padding-bottom: 8px;
    max-width: 400px;
    line-height: 25px;
  }
  .footer-icons{
    color: #080a3c;
    height: 17px;
  }
  
  .foot-head{
    font-family: sans-serif;
    font-size: 25px;
    color: #ffffff;
    padding-left: 10px;
  }
  .foot-logo-2{
    display: flex;
    align-items: center;
  }
.company-supp-con{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
  @media screen and (max-width:767px) {
    .foot{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .company-supp-con{
      width: 88%;
    }
  }

  @media screen and (min-width:540px) and (max-width:991px) {
    .company-supp-con{
      width:100%;
    }
    .foot{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer-cont-mob-v{
      width: 60%;
    }
    .foot-con-mob-v{
      width: 100%;
    }
  }

  @media screen and (min-width:380px) and (max-width:539px){
     .footer-cont-mob-v{
      width: 70%;
    }
    .new-end-contact-no{
      margin-left: 10px;
    }
  }

  @media screen and (max-width:379px){
    .new-end-contact-no{
      margin-left: 10px;
    }
  }