.seo-new-tools-list-container-google-analytics{
    width: 90%;
}
.google-analytics-rote{
    display: flex !important;
    flex-direction: column !important;
}

.google-analytics-img{
    height: 80px;
}
.google-analytics-head{
    font-size: 17px;
    padding-top: 20px;
    line-height: 25px;
}

.google-analytices-main-head{
    font-size: 27px;
    width: 90%;

}
.google-analytics-image{
    align-self: center;
    width: 50%;
}

@media screen and (max-width:912px){
    .google-analytics-image{
        width: 100%;
    }
    .google-analytices-main-head{
        width: 90%;
        line-height: 43px;
    }
}

@media screen and (max-width:540px){
    .google-analytics-rote{
        width: 100% !important;
    }
    .google-analytices-main-head{
        font-size: 21px;
        line-height: 35px;
    }
    .google-analytics-image{
        width: 100%;
        margin-top: 25px;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .google-analytics-image{
        width: 100%;
    }
}