.other-page-color{
    color: rgb(19, 19, 80);
    font-weight: 520;
    letter-spacing: 0.5px!important;
}
.other-page-color-2{
    padding-top: 8px;
}
.nav-ther-page-link-about-us{
     color: rgb(19, 19, 80);
}
.nav-color-link{
    color: #313979;
    font-weight: bold;
}
.about-ot{
    height: 80px;
    margin-top: 45px;
}
.nav-main-container-ot-2{
    top: 0px;
    position: fixed;
    background-color: #ffffff;
    z-index: 5;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
