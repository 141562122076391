.business-pre-img-2{
    border-radius: 10px;
}
.animation-route-top-con{
    width: 50%;
}
@media screen and (max-width:912px){
    .d-main-img-mob{
        width: 100%;
    }
    .d-main-img-mob-2{
        width: 100%;
        margin-bottom: 30px;
    }
    .animation-route-top-con{
        width: 100%;
    }
}

@media screen and (max-width:539px){
    .av-container{
        flex-direction: column;
    }
    .av-head{
        text-align: center;
    }
}

.vie-btn-c{
    width: 80px;
}