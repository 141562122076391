.container-product-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 98%;
  }
  .container-product-main-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #container-product {
  
    width: 28%;
    height: auto;
    margin: 15px;
    overflow: hidden;
    border: 10px solid #EEE5E5;
    outline: 5px dashed #EEE5E5;
    position: relative;
  }
  @media screen and (max-width:540px){
    #container-product{
        width: 100%;
    }
  }
  #container-product .product-pack-route-img {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
    transition: all 0.3s ease-out;
    position: relative;
  
  }
  
  
  #container-product:hover .product-pack-route-img {
    transform: scale(1.1, 1.1);
  
  }
  
  
  @media screen and(min-width:767px) and  (max-width:992px){
    .new-cha-ppp{
      font-size: 18px;
      text-align: center;
    }
  }