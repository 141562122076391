.happy-clients-container {
	display: flex;
	justify-content: center;
	align-items: center;
   width: 100%;
   background-image: linear-gradient(to right,rgb(92, 92, 223),rgb(30, 30, 233));
   min-height: 250px;
   max-height: fit-content;
  }

  @media screen and (max-width:767px) {
   .happy-clients-container{
      flex-direction: column;
      padding-top: 25px;
      padding-bottom: 25px;
   }
  }