.product-pack-des-im{
    width: 42%;
    height: 400px;
}
.packaging-design-ty-con{
    align-items: center;
    padding: 25px;
    padding-top: 30px;
    border: 1px solid aliceblue;
}
.packaging-design-ty-con-img{
    height: 90px;
}

.packaging-back-banner-img{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342160/yfdabizwm350mqba1gbs.jpg");
    padding-top: 40px;
    padding-bottom: 40px;
}

@media screen and (max-width:912px){
    .mob-v-prod-view{
        width: 100%;
        margin-top: 20px;
    }
    .mob-v-prod-view-1{
        width: 100%;
        margin-bottom: 30px;
    }
}

@media screen and (min-width:541px) and (max-width:767px){
    .card-prod-anime-con{
        width: 30%;
        margin: 10px;
    }
    .type-of-prod-mob-small-para{
        width: 90%;
        text-align: center;
    }
}

@media screen  and (max-width:540px){
    .card-prod-anime-con{
        width: 90%;
        margin: 10px;
    }
    .packaging-design-ty-con-img{
        margin-bottom: 10px;
    }
    .type-of-prod-mob-small{
        width: 90%;
        font-size: 20px;
        text-align: center;
        line-height: 30px;
    }
    .type-of-prod-mob-small-para{
        width: 90%;
        font-size: 18px;
        text-align: center;
    }
}
.tools-all-detail-con-2{
    text-align: left;
}

@media screen and (max-width:912px){
    .mobile-logo-desi-con-p{
        width: 100%;
    }
    .tools-all-detail-con-3{
        margin-left: 20px !important;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .artile-write-img-vv-1{
        width: 100% !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}