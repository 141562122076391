
.img-list-bd {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    }
    .img-list-bd .img-list-li {
    border: 5px solid transparent;
    box-sizing: border-box;
    width: 30%;
    float: left;
    position: relative;
    cursor: pointer;
    margin: 10px;
    }
    .img-list-bd .img-list-img {
    max-width: 100%;
    vertical-align: middle;
    }
    .img-list-li{
      border-radius: 10px;
    }
    
   
    
    /* The hover effect
    -------------------------------------------------------------- */
    .img-list-bd .img-list-li:before {
    transition: all .5s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: aliceblue;
    transform: scale(0);
    }
    .img-list-bd .img-list-li:hover:before {
    opacity: .5;
    transform: scale(1);
    }
    .img-list-bd .img-list-li:after {
    transition: all .6s ease .2s;
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 1px solid #aaa;
    background: aliceblue;
    opacity: 0;
    transform: scale(0);
    }
    .img-list-bd .img-list-li:hover:after {
    opacity: .35;
    transform: scale(1);
    }

    @media screen and (min-width:541px) and (max-width:767px){
        .img-list-bd .img-list-li {
            width: 40%;
        }
    }

    @media screen and (max-width:540px){
        .img-list-bd .img-list-li {
            width: 100%;
        }
    }