.social-media-route-img-main{
    width: 50%;
    align-self: flex-start;
}


@media screen and (max-width:912px){
    .social-media-route-img-main-mobile{
        width: 100%;
        align-self: center;
    }
}

.our-wordpress-development{
    text-align: center;
}

@media screen and (min-width:768px) and (max-width:991px){
    .social-media-route-img-main-mobile{
        width: 100%;
        align-self: center;
    }
}