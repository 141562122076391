.services-submenu {
    width: 10rem;
    position: absolute;
    top: 150px;
    list-style: none;
    text-align: start;
  }
  
  .services-submenu li {
    background:#ffffff;
    cursor: pointer;
   
  }
  
.about-drop{
  font-family: sans-serif;
  font-size: 18px;
  padding-bottom: 10px;
  color: #313979;
}
  
  .services-submenu.clicked {
    display: none;
  }
  
  .submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    padding: 16px;

  }

  .nav-item-dropdown{
    font-size: 30px;
    font-family: sans-serif;
    text-decoration: none;
    color: #313979;
  }
  .dropdown-main-list-item{
    padding-left: 20px;

    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
  }

  .dropdown-main-list-item:hover{
    background-color: #f1f6ff;
  }


  .services-submenu-ab{
    width: 300px;
  }

  .about-drop-ab{
    font-size: 17px;
    margin-bottom: 15px;
  }

  .about-lin-ab{
    color: #313979;
  }

  .about-lin-ab:hover{
    font-weight: 600;
  }

  .drop-down-mr{
    top:100px;
  }