.spotify-developer-main-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding-top: 50px;
    padding-bottom: 50px;
}
.spotify-developer-main-con-1{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.spotify-developer-main-con-1-heading{
    font-family: sans-serif;
    font-size: 35px;
    line-height: 45px;
    padding-bottom: 20px;
}

.spotify-developer-main-con-1-para{
    font-family: sans-serif;
    font-size: 17px;
    background-image: linear-gradient(to right,rgb(89, 89, 253),#0006b1,#0013de,rgb(26, 26, 202));

    padding: 5px;
    color: #ffffff;
    font-weight: bold;
}

.spotify-developer-main-con-1-para-2{
    font-family: sans-serif;
    font-size: 17px;
    padding-top: 20px;
}

.img-spotify-devel{
    width: 30%;
}

.spotify-developer-div-con{
    width: 50%;
}

.spotify-div-con-developer{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 50px;
}

.spotify-div-con-developer-inner{
    width: 31%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 10px;
    height: 270px;
    border-radius:5px;
    transition: 0.5s;
}
.spotify-div-con-developer-inner:hover{
    margin-top: -15px;
    transition: 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.spotify-div-con-developer-inner-1{
    background-image: linear-gradient(to right,rgb(89, 89, 253),#0006b1,#0013de,rgb(26, 26, 202));

    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.spotify-inner-heading-dev{
    font-family: sans-serif;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    text-align: center;
}

.spotify-inner-heading-dev-para{
    padding: 20px;
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media screen and (min-width:992px) and (max-width:1200px){
    .spotify-developer-main-con-1{
        width: 95%;
    }
    .spotify-div-con-developer{
        width: 100%;
    }
}

@media screen and (min-width:767px) and (max-width:991px){
    .spotify-developer-main-con-1{
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }
    .spotify-div-con-developer{
        width: 80%;
    }
    .spotify-div-con-developer-inner{
        width: 46%;
        height: 310px;
    }
    .spotify-developer-div-con{
        width: 100%;
    }
    .img-spotify-devel{
        width: 100%;
    }
    .spoti-main-im{
        width: 100%;
        margin-top: 20px;
    }
    .animation-route-top-con{
        width: 90%;
    }
    .spoti-main-im{
        width: 100% !important;
    }
   
}

@media screen and (max-width:766px){
    .spotify-developer-main-con-1{
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
    .spotify-div-con-developer{
        width: 100%;
    }
    .spotify-div-con-developer-inner{
        width: 92%;
        height: fit-content;
    }
    .spotify-developer-div-con{
        width: 100%;
    }
    .img-spotify-devel{
        width: 100%;
    }
    .spotify-inner-heading-dev{
        padding: 10px;
        font-size: 18px;
    }
    .spotify-div-con-developer-inner-1{
        height: 63px;
    }
    .spotify-developer-main-con-1-heading{
        font-size: 30px;
        line-height: 40px;
    }
    .spotify-developer-main-con-1-para{
        line-height: 30px;
    }
}