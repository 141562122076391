* {
    -webkit-font-smoothing: antialiased;
    -moz-os-font-smoothing: grayscale;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
    vertical-align: baseline
}

body {
    font-family: open sans,sans-serif;
    color: #6a6a8e;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    font-size: 17px;
    outline: 0;
    overflow-x: hidden
}

article,aside,details,figcaption,figure,footer,header,nav,section,summary {
    display: block
}

h1,h2,h3,h4,h5,h6 {
    font-family: Poppins,sans-serif;
    color: #050748;
    font-weight: 600
}

h1,h2,h3,h4,h5,h6,p,ul {
    padding: 0;
    margin: 0
}

ul {
    list-style: none
}

a:hover {
    text-decoration: none
}

button:focus {
    outline: 0!important
}

.fa-icon {
    margin-left: 5px;
    font-size: 12px
}

.header-heading .fa-icon {
    margin-left: 5px;
    font-size: 12px;
    animation: 2s infinite arrowanim;
    -webkit-animation: 2s infinite arrowanim
}

.common-heading {
    padding: 0;
    text-align: center;
    position: relative;
    z-index: 10
}
  
.common-heading h2 {
    margin-bottom: 60px
}

.inner-heading h2 {
    margin-bottom: 30px
}

.v-center {
    align-self: center;
    align-items: center
}

button {
    position: relative;
    overflow: hidden
}

.lnk {
    position: relative;
    overflow: hidden
}

.animation,.circle,.circle2,.clients-logo,.custom-btn,.dropdown-toggle,.hoshd,.hover-scale img,.icon-set,.isotope_item,.link-hover,.main-header,.main-header.sticky,.navbar,.s-block,.sticky,.sub-menu-sec a,a,button,input {
    -webkit-transition: 1.2s cubic-bezier(.17,.85,.438,.99);
    -o-transition: 1.2s cubic-bezier(.17,.85,.438,.99);
    transition: 1.2s cubic-bezier(.17,.85,.438,.99)
}

.no-shadow {
    box-shadow: none!important
}

span.circle {
    background: rgba(255,255,255,.2);
    display: block;
    position: absolute;
    top: -10%;
    right: -130px;
    width: 200px;
    height: 200px;
    border-radius: 50%
}

.lnk:hover span.circle {
    top: -10%;
    right: -80px
}

span.circle2 {
    background: rgba(255,255,255,.2);
    display: block;
    position: absolute;
    top: -10%;
    right: -50px;
    width: 80px;
    height: 80px;
    border-radius: 50%
}

.lnk:hover span.circle2 {
    top: -10%;
    right: -40px
}

.font-15 {
    font-size: 15px
}

.f-medium {
    font-weight: 600
}

.f-bold {
    font-weight: 700
}

.light-blue {
    background: #f8fdff
}

.text-radius {
    border-radius: 3px;
    padding: 0 3px
}

.text-primary {
    color: #fe4c1c
}

.text-second {
    color: #e91e63
}

.text-bold {
    font-weight: 700
}

.header-icon-nav a {
    background: #ffffff7a;
    padding: 12px;
    margin: 5px;
    border-radius: 50%;
    color: #0a2233
}

.sticky .dsk-logo {
    line-height: 70px
}

.sticky .custom-nav .nav-list li a.menu-links {
    line-height: 70px
}

.main-header.sticky {
    height: 70px
}

.top-header {
    position: relative;
    z-index: 999
}

.navbar .mobile-menu {
    display: none
}

.navbar .mobilemenu {
    color: #0a2233;
    top: -4px;
    position: relative
}

.navbar .mobilemenu i {
    font-size: 25px
}

.mob-nav {
    display: flex
}

.mob-nav li {
    position: relative
}

.ml8 {
    margin-left: 8px
}

.animate {
    animation-duration: .4s;
    -webkit-animation-duration: .4s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both
}

.top-header .dropdown-toggle::after {
    display: inline-block;
    margin-left: 3px;
    vertical-align: baseline;
    content: "+";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    border-radius: 100px
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn
}



.card-bg-a {
    background: #3faee5;
    background: -moz-linear-gradient(top,#3faee5 0,#6d03ce 100%);
    background: -webkit-linear-gradient(top,#3faee5 0,#6d03ce 100%);
    background: linear-gradient(to bottom,#3faee5 0,#6d03ce 100%)
}

.card-bg-b {
    background: #ff92f6;
    background: -moz-linear-gradient(top,#ff92f6 0,#b300a5 100%);
    background: -webkit-linear-gradient(top,#ff92f6 0,#b300a5 100%);
    background: linear-gradient(to bottom,#ff92f6 0,#b300a5 100%)
}

.card-bg-c {
    background: #3e35b7;
    background: -moz-linear-gradient(top,#3e35b7 0,#4c4aff 100%);
    background: -webkit-linear-gradient(top,#3e35b7 0,#4c4aff 100%);
    background: linear-gradient(to bottom,#3e35b7 0,#4c4aff 100%)
}

.card-bg-d {
    background: #f72c8c;
    background: -moz-linear-gradient(top,#f72c8c 0,#b82dcf 100%);
    background: -webkit-linear-gradient(top,#f72c8c 0,#b82dcf 100%);
    background: linear-gradient(to bottom,#ffa76a 0,#ff8e38 100%)
}

.card-bg-e {
    background: #5782ea;
    background: -moz-linear-gradient(top,#5782ea 0,#2251af 100%);
    background: -webkit-linear-gradient(top,#5782ea 0,#2251af 100%);
    background: linear-gradient(to bottom,#5782ea 0,#2251af 100%)
}

.card-bg-f {
    background: #95e5ff;
    background: -moz-linear-gradient(top,#95e5ff 0,#009688 100%);
    background: -webkit-linear-gradient(top,#95e5ff 0,#009688 100%);
    background: linear-gradient(to bottom,#95e5ff 0,#009688 100%)
}

.shape-bg3:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .03
}

.hero-bg-bg1:before {
    content: "";
    position: absolute;
    height: 100vh;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    background-position: left 0;
    background-size: contain;
    background-repeat: no-repeat
}

.hero-section {
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100vh;
    align-items: center;
    display: grid
}

.btn-main:hover {
    color: #fff
}

.btn-main {
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins,sans-serif;
    padding: 0 32px;
    line-height: 56px;
    text-align: center;
    outline: 0;
    color: #fff;
    background-color: #ff1f8e;
    border: none;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    -webkit-box-shadow: 0 10px 15px 0 rgba(233,30,99,.15);
    box-shadow: 0 10px 15px 0 rgba(233,30,99,.15)
}

.header-heading h1 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 700;
    margin-bottom: 30px
}

.header-heading p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35px
}

.about-agency .common-heading h2 {
    margin-bottom: 20px
}

.about-agency {
    position: relative;
    overflow: hidden
}

.about-agency:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0
}

.text-l {
    text-align: left!important
}

.common-heading>span {
    color: #e60072;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 15px;
    display: block
}

.quote {
    font-weight: 500;
    padding: 25px;
    position: relative;
    border-left: 4px solid #ff358f;
    margin: 30px 0;
    background: #fafafb;
    color: #6a6a8e;
    font-family: Poppins,sans-serif
}

.user- .user-image img {
    border-radius: 100%;
    width: 80px
}

.user-info {
    margin: 0 0 0 20px;
    text-align: left
}

.user-info p span {
    font-weight: 700
}

.service-section {
    background: #fff;
    position: relative;
    overflow: hidden
}

.image-block img {
    width: 100%;
    border-radius: 8px
}



.owl-carousel .owl-dots .owl-dot:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    opacity: .4;
    background: #ff1f8e;
    width: 4px;
    height: 4px
}

.owl-carousel .owl-dots .owl-dot.active:after,.owl-carousel .owl-dots .owl-dot.active:before {
    opacity: 1
}

.owl-carousel .owl-dots .owl-dot.active:after,.owl-carousel .owl-dots .owl-dot:focus:after,.owl-carousel .owl-dots .owl-dot:hover:after {
    opacity: 1
}

.light-bgs {
    background: rgba(255,255,255,.52);
    padding: 30px;
    border-radius: 8px
}

.enquire-form {
    background: #d6fffc;
    background: -moz-linear-gradient(top,#d6fffc 0,#ffeff5 100%);
    background: -webkit-linear-gradient(top,#d6fffc 0,#ffeff5 100%);
    background: linear-gradient(to bottom,#d6fffc 0,#ffeff5 100%)
}


.follow-label {
    display: flex
}

.lead-gen-client .clients-logo {
    background: #fff;
    padding: 0 15px;
    max-height: 150px;
    min-height: 150px;
    margin: 0;
    border: 1px solid #d4f0ff;
    align-items: center;
    display: flex;
    position: relative;
    border-radius: 8px;
    -webkit-box-shadow: -20px 20px 40px #ece0f2,20px -20px 40px #fff8ff;
    box-shadow: -20px 20px 40px #ece0f2,20px -20px 40px #fff8ff
}

.itm-media-object .media .img-media {
    width: 65px;
    background: #d3f3ff;
    padding: 10px;
    border-radius: 8px
}

.itm-media-object .media-body {
    margin-left: 30px
}

.why-choos-lg {
    position: relative
}

.why-choos-lg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0
}

.itm-media-object h4 {
    text-align: left
}

.srvc-bg-nx {
    background: #fff;
    overflow: hidden;
    border-radius: 6px;
    margin: 0 10px;
    height: 240px;
    -webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0,0,0,.059),0 8.2px 8.9px -30px rgba(0,0,0,.071),0 25px 40px -30px rgba(0,0,0,.2);
    box-shadow: 0 3.4px 2.7px -30px rgba(0,0,0,.059),0 8.2px 8.9px -30px rgba(0,0,0,.071),0 25px 40px -30px rgba(0,0,0,.2)
}

.about-sec-rpb {
    background: #fff8f2
}

.about-sec-rpb.pad-tb {
    padding-bottom: 200px
}

section.about-sec-rpb.pad-tb {
}

section.about-sec-rpb.pad-tb {
}

.badges-content {
    text-align: center
}

.badges-content p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 50px;
    margin-top: 10px
}

.w-txt h2 {
    color: #fff
}

.common-heading.w-txt>span {
    color: #fff
}

.-service-list li:first-child {
    padding-left: 0
}

.-service-list li {
    text-transform: uppercase;
    list-style: none;
    padding: 0 8px;
    border-right: 1px solid #d4d4d4;
    display: inline-block
}

.-service-list li:last-child {
    border-right: none
}

.service-sec-list p {
    font-size: 15px;
    line-height: 24px;
    margin: 14px 0 0
}

.-service-list li a {
    font-size: 13px;
    color: #6a6a8e;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    font-weight: 700
}

.link-prb {
    display: block
}

.col-lg-6-cus {
    width: 40%
}

.col-lg-3-cus {
    width: 30%
}

.mt30- {
    margin-top: 20px
}

.text-w,.text-w a,.text-w h1,.text-w h2,.text-w h3,.text-w h4,.text-w h5,.text-w h6 {
    color: #fff
}

.service-section-prb {
    position: relative;
    margin-top: -200px
}

.bg-shape-dez {
    position: relative
}

.bg-shape-dez::after,.bg-shape-dez::before {
    position: absolute;
    z-index: 0;
    content: '';
    width: 50%;
    height: 50%;
    background: #007bff;
    background: -webkit-linear-gradient(left,#007bff 0,#ff1f8e 100%);
    background: linear-gradient(to right,#007bff 0,#ff1f8e 100%)
}

.bg-shape-dez::after {
    top: -10px;
    right: -10px;
    border-radius: 0 8px 0 0
}

.single-image img {
    position: relative;
    z-index: 5;
    border-radius: 8px
}

.bg-shape-dez::before {
    bottom: -10px;
    left: -10px;
    border-radius: 0 0 0 8px
}

.single-image img {
    position: relative;
    z-index: 5;
    border-radius: 8px
}

.srcl1 {
    background: #f3f5ff;
    border-bottom: 4px solid #b0bdff
}

.srcl2 {
    background: #fff3e6;
    border-bottom: 4px solid #ffc093
}

.srcl3 {
    background: #fbeefd;
    border-bottom: 4px solid #f6bcff
}

.srcl4 {
    background: #effdff;
    border-bottom: 4px solid #9ff3ff
}

.srcl5 {
    background: #fbfff1;
    border-bottom: 4px solid #ffdaa2
}

.service-sec-list p {
    display: none
}

.service-sec-list {
    padding: 52px 25px
}

.service-sec-brp {
    padding: 30px 25px
}

.service-sec-list img {
    -moz-transition: width .5s,height .5s;
    -webkit-transition: width .5s,height .5s;
    transition: width .5s,height .5s;
    height: 60px;
    width: 60px;
    margin-bottom: 10px
}

.service-sec-list:hover {
    padding: 25px
}

.service-sec-list:hover img {
    height: 0;
    width: 60px
}

.service-sec-list:hover p {
    display: block
}

.service-sec-list,.service-sec-list:hover,.service-sec-list:hover p {
    -moz-transition: all .8s;
    -o-transition: all .8s;
    -webkit-transition: all .8s;
    transition: all .8s
}

.-cta-btn {
    margin: 0 auto;
    display: inline-block;
    text-align: center;
    width: 100%
}

.-cta-btn p {
    font-size: 30px;
    color: #050748;
    margin: 0 30px 0 0
}

.-cta-btn p span {
    font-weight: 700
}

.free-cta-title {
    display: inline-flex
}

.key-block.shadow-box {
    padding: 15px;
    margin-bottom: 20px;
    border-top: 3px solid #a241bb;
    transition: all .3s ease-out 0s
}

.key-icon {
    margin-right: 15px;
    float: left
}

.clients-logos ul li p {
    font-size: 12px;
    padding-top: .5em;
    letter-spacing: 0;
    font-weight: 300;
    color: #959595
}



h1 {
    font-size: 45px;
    line-height: 57px
}

h2 {
    font-size: 40px;
    line-height: 52px
}

h3 {
    font-size: 34px;
    line-height: 46px
}

h4 {
    font-size: 22px;
    line-height: 32px
}

h5 {
    font-size: 20px;
    line-height: 30px
}

h6 {
    font-size: 16px;
    line-height: 26px
}

p {
    font-size: 17px;
    line-height: 28px
}

.pad-tb {
    padding-top: 100px;
    padding-bottom: 100px
}

.pr--100 {
    padding-right: 100px
}

.pl--100 {
    padding-left: 100px
}

.pl--50 {
    padding-left: 50px
}

.pr--50 {
    padding-right: 50px
}

.mr15 {
    margin-right: 15px
}

html .pl5 {
    padding-left: 5px!important
}

html .pt0 {
    padding-top: 0
}

html .pm0 {
    padding-bottom: 0
}

html .mt0 {
    margin-top: 0
}

html .mb0 {
    margin-bottom: 0
}

html .mt10 {
    margin-top: 10px
}

html .ml10 {
    margin-left: 10px
}

html .mt20 {
    margin-top: 20px
}

html .mt30 {
    margin-top: 30px
}

html .mt40 {
    margin-top: 40px
}

html .mt50 {
    margin-top: 50px
}

html .mt60 {
    margin-top: 60px
}

html .mt70 {
    margin-top: 70px
}

html .mt80 {
    margin-top: 80px
}

html .mt90 {
    margin-top: 90px
}

html .mt100 {
    margin-top: 100px
}

html .mb10 {
    margin-bottom: 10px
}

html .mb20 {
    margin-bottom: 20px
}

html .mb25 {
    margin-bottom: 25px
}


.ouroffice a {
    color: #050748;
    margin-top: 20px;
    display: block
}

.ouroffice h4 {
    margin-bottom: 8px
}

.form-block {
    overflow: hidden
}

.leadpopup .modal-body {
    padding: 0
}

.leadpopup .modal-dialog {
    max-width: 800px
}

.leadpopup .modal-content {
    border-radius: 0
}

.innerbody {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 30px
}

.innerleft {
    padding: 30px
}

.innerright {
    background-size: cover
}
.our-ser-para-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.our-ser-para{
    text-align: center;
    width: 80%;
}
.cont-2{     
    display: flex;
    flex-wrap: wrap;
}
.developent-img {
    height: 70px;
  }
.our-services-bg{
   /* background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706686532/xakab3qrjwnoyjnoqthd.jpg"); */
   background: linear-gradient(135deg,#3F74A3,#0000AA 32%,#4D4DFF);
   background-size: cover;
}
  .hire-name-col{
    color: blue !important;
  }

.leadbtnclose {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px
}

input {
    accent-color: #ff1f8e
}


.top-rated-container-head{
    color: #ffffff;
    padding-bottom: 15px;
}

.top-rated-container-head-span{
    color: blue;
}

.our-ser-para-col{
    color: #ffffff;
}

.integrated-container-chng-back{
 /*   background-image: linear-gradient(to right,grey,blue);*/
 background: linear-gradient(135deg,#3F74A3,#0000AA 32%,#4D4DFF);

}

.button-enquire-now-container-n{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding-top: 50px;
}


@media screen and (max-width:767px) {
    .mobile-innovate-our{
        max-width: 300px;
        text-align: center;
    }
    .mobile-innovate-our-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-innovate-our-head{
        font-size: 32px;
        line-height: 45px;
    }
    .intehrated-serv-mobile-view{
        flex-direction: column;
    }
    .integrated-con-mobile-view{
        width: 100% !important;
        margin-top: 15px;
    }
    .integrated-con-mobile-view-inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .integrated-con-mobile-view-inner-para{
        text-align: center;
    }
    .enquire-now-text-2-mob-view{
        font-size: 17px !important;
        margin-right: 10px !important;
        padding-right: 0px !important;
        text-align: center;
        padding-left: 10px;
    }
    .mob-view-button-hire{
        margin-right: 10px;
        border-radius: 10px;
    }
    .mob-view-btn-pa{
        line-height: 20px;
        border-radius: 0px;
        height: 50px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 7px;
        padding-right: 0px;
    } 
}

@media screen and (min-width:768px) and (max-width:991px) {
    .integrated-con-mobile-view{
        width: 50% ;
    }
    .mobile-innovate-our{
        line-height: 28px;
    }
    .mobile-innovate-our-head{
        width: 95%;
        text-align: center;
    }
    .top-rated-container-head{
        width: 90%;
    }
}

@media screen and (max-width:540px){
    .mobile-innovate-our-head{
        width: 90%;
        line-height: 40px;
    }
    .mobile-innovate-our{
        line-height: 28px;
    }
    .our-ser-para-col{
        width: 88%;
    }
}

.head-ani-gradient{
    text-align: center;
  font-size: 15px;
  background-image: -webkit-linear-gradient(92deg, #f35626,aliceblue, #feab3a);
  background-size: 200% auto;
  line-height: 28px;
  padding-bottom: 10px;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 1s linear infinite;
}
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
