.careers-rote-img{
    width: 33%;
}

.career-rote-head{
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #ffffff;
    font-family: sans-serif;
}
.career-rote-container{
    background-image: linear-gradient(to right,rgb(89, 89, 253),#0006b1,#0013de,rgb(26, 26, 202));
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70vh;
}

.career-rote-para{
    font-family: sans-serif;
    font-size: 30px;
    color: #ffffff;
    font-weight: 600;
    width: 600px;
    line-height: 35px;
    padding-top: 10px;
}

.career-rote-detail-img{
    width: 45%;
}

.career-name-form-cont{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 50%;
    margin-top: 15px;
}

.career-name-form{
    font-size: 15px;
    color: black;
    
    font-family: sans-serif;
    letter-spacing: 0.5px;
}

.career-name-star-form{
    color: red;
    padding-top: 5px;
}
.career-input-text{
    margin-top: 18px;
    height: 30px;
    border: none;
    border-bottom: 1px solid darkgray;
    border-radius: 7px;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding-left: 5px;
    font-weight: normal;
    padding-bottom: 5px;
}

.career-inner-form-div{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.career-inner-form-div-main{
    width: 50%;
}

.career-img-form-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-top: 70px;
}

.career-main-container-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.career-name-form-cont-2{
    margin-top: 25px;
    width: 100%;
}

.career-apply-job{
    font-size: 16px;
}

.career-input-text-select{
    font-size: 17px;
    color: gray;
    padding-bottom: 10px;
}

.submit-career-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:985px){
    .career-rote-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        height: fit-content;
        padding-bottom: 30px;
    }
    .careers-rote-img{
        width: 60%;
    }
    .career-p-m{
        width: 90%;
    }
}

@media screen and (max-width:713px){
   .career-rote-para{
    width: 90%;
    font-size: 28px;
   }
   .career-mob-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
   }
}

@media screen and (max-width:767px){
    .career-img-form-cont{
        flex-direction: column;
    }
    .career-rote-detail-img{
        width: 80%;
        margin-bottom: 15px;
    }
    .career-inner-form-div-main{
        width: 100%;
    }
}


@media screen and (max-width:540px){
    .career-name-form{
        width: 100%;
    }
    .career-inner-form-div{
        width: 100%;
        flex-direction: column;
    }
    .career-name-form-cont{
        width: 100%;

    }
    .career-inner-form-div-main{
        width: 100%;
        flex-direction: column;
    }
    .cc-head{
        font-size: 25px;
    }
    .career-rote-detail-img{
        width: 100%;
        margin-bottom: 15px;
    }
}