.con-sc-md{
    width: 90%;
}

.con-sc-md-main{
    background-color: #f5f5f5;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}