.contact-us-office-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.contact-us-office-main-container-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 98%;
}
.our-offices-img {
    width: 90%;
}
.our-locations-contact-us {
    font-family: sans-serif;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
    background: linear-gradient(
        to right,
        #7953cd 20%,
        #00affa 30%,
        rgb(197, 59, 59),
        #0190cd 70%,
        #764ada 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 2s ease-in-out infinite alternate;
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.our-offices-contact-us {
    font-family: sans-serif;
    font-size: 40px;
    color: navy;
    font-weight: bold;
    padding-bottom: 45px;
}
.office-location-head {
    font-family: sans-serif;
    font-size: 23px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.office-location-para {
    font-family: sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #555d50;
    padding-bottom: 10px;
}

.contact-us-office-main-icon {
    font-size: 26px;
    color: #636363;
}
.info-mail-para {
    font-family: sans-serif;
    font-size: 20px;
    color: #636363;
    padding-left: 6px;
    padding-top: 3px;
}
.io-mail-contact-us {
    display: flex;
    align-items: center;
    height: 42px;
}

.contact-us-office-main-icon-btn {
    height: 53px;
    width: 53px;
    border-radius: 40px;
    background-color: transparent;
    border: 1px solid darkgray;
    margin-right: 8px;
    margin-top: 20px;
}
.btn-cont-2 {
    font-size: 20px;
    color: black;
}

.btn-cont-3 {
    font-size: 26px;
    color: black;
}

.contact-us-office-locate-container {
    width: 27%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid blue;
    min-height: 510px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-right: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.contact-us-btn-ic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.contact-us-office-main-icon-btn:hover {
    background-color: navy;
    border: none;
    .contact-us-office-main-icon {
        color: #ffffff;
    }
}
@media screen and (max-width: 540px) {
    .contact-us-office-main-container-2 {
        flex-direction: column;
        justify-content: center;
    }
    .contact-us-office-locate-container {
        width: 90%;
        margin-right: 0px;
        padding-bottom: 20px;
    }
    .our-offices-contact-us {
        font-size: 28px;
    }
}

@media screen and (min-width: 541px) and (max-width: 767px) {
    .contact-us-office-locate-container {
        width: 45%;
        margin: 10px;
        padding-bottom: 20px;
    }
}
