.bg-container{
    background-color: transparent;
}


  .home-main-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 40px;
  }

  .home-page-main-heading{
    font-family: sans-serif;
    font-size: 48px;
    color: #ffffff;
    font-weight: bold;
    line-height: 60px;
    max-width: 500px;
  }
  @media screen and (min-width:768px) and (max-width:820px) {
    .home-page-main-heading{
      font-size:40px;
    }
  }
  .home-page-main-paragraph{
    font-family: sans-serif;
    font-size: 17px;
    color: #ffffff;
    max-width: 500px;
    line-height: 25px;
    padding-top: 30px;
  }
  
  .button-home-main{
    color: #ffffff;
    background-color: #e711e7;
    font-family: sans-serif;
    font-size: 21px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 20px;
    cursor: pointer;
  }
  .home-creativity-head{
    width: 50%;
  }
  
  .home-main-container-1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-shrink: 1;
  }
  .home-main-container-image{
    width: 50%;
    transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  }
  @keyframes float {
    0% {
      transform: translatey(10px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(10px);
    }
  }
  .arrow-down-icon{
    font-size: 30px;
  }
  .nav-link-item-container{
    display: flex;
    align-items: center;
  }

  @media screen and (max-width:767px){
    .home-main-container{
      flex-direction: column;
      align-items: flex-start;
      width:90%;
      margin-top: 90px;
    }
    .home-page-main-heading{
      font-size: 35px;
      line-height: 48px;
    }
    .home-creativity-head{
      width: 100%;
    }
    .home-main-container-1{
      margin-top: 80px;
      margin-bottom: 20px;
    }
    .home-main-container-image{
      width: 100%;
      margin-top: 30px;
    }
    .home-page-main-paragraph{
      font-size: 16px;
    }
    .button-home-main{
      font-size: 16px;
    }
  }
.nav-mob-m-m{
  margin-top: -160px;
}
 
  @media screen and (min-width:280px) and (max-width:300px)  {
    .button-home-main{
      font-size: 13px;
    }
    .home-page-main-paragraph{
      font-size: 15px;
    }
  }

  @media screen and (min-width:768px) and (max-width:914px){
    .home-creativity-head{
      margin-top: 100px;
      font-size: 30px;
    }
  }