.technologies-sec {
    padding: 50px 0
}

.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.tabs__label {
    color: royalblue;
    order: 1;
    display: block;
    padding: 10px 20px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold
}

.tabs__tab {
    order: 99;
    flex-grow: 1;
    width: 100%;
    display: none;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap
}

.tabs__input {
    display: none
}

.tabs__input:checked+.tabs__label {
    color: #143a7b;
    border-bottom: 2px solid #143a7b;
    display: block;
    font-weight: 600;
    font-size: 22px
}

.tabs__input:checked+.tabs__label+.tabs__tab {
    display: flex
}

.img-card {
    text-align: center;
    padding: 0 0;
    background: #f7f7f7;
    border-radius: 8px;
    margin: 0 10px 20px;
    float: left
}


.img-card__btn {
    display: block;
    background: #143a7b;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 8px;
    line-height: 22px;
    font-size: 16px;
    border-radius: 0 0 6px 6px
}

.home-head-2{
    text-align: center;
}

@keyframes move {
    from {
        transform: translateX(100%)
    }

    to {
        transform: translateX(-100%)
    }
}

.event-info-container {
    width: 95%;
    margin: 0 auto
}

.event-landing {
    font-size: 13px;
    padding: 15px 0 15px
}

.event-landing__cards {
    display: flex;
    flex-wrap: wrap
}

.event-landing__cards__item {
    background-color: #ddd;
    margin-bottom: 15px;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 100%
}

.event-landing__cards__item__event-box {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    position: relative;
    color: #fff;
    padding: 85px 0 25px;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center
}


.event-landing__cards__item__event-box:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.6)
}

.event-landing__cards__item__event-box__content {
    display: block;
    position: relative
}

.event-landing__cards__item__event-box__content--coming-soon-title {
    position: relative;
    top: -40px;
    color: #84ff10;
    font-weight: 600
}

.event-landing__cards__item__event-box__content--event-title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 25px;
    color: #f5a956
}

.event-landing__cards__item__event-box__content--event-date {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px
}

.event-landing__cards__item__event-box__content--event-tag-line {
    font-style: italic;
    font-size: 18px
}

.event-landing__cards__item:hover .event-landing__cards__item__event-box {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}


.event-participants {
    padding-bottom: 25px
}

.event-participants__title {
    text-align: center
}

.event-participants__participants {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 206px;
    justify-content: flex-end
}

.event-participants__participants--ceo2-participants {
    justify-content: center;
    padding-top: 100px
}

.event-participants__participants__item {
    text-align: center;
    padding: 15px;
    margin: 0 0 14px;
    width: 206px;
    transition: all .5s ease 0s
}

.event-participants__participants__item--item-border {
    border: 1px solid #ccc;
    margin: 10px
}

.event-participants__participants__item:hover {
    box-shadow: 0 15px 10px -10px rgba(0,0,0,.5),0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(0,0,0,.1) inset
}

.event-participants__participants__item__img {
    height: 60px
}

.event-participants__participants__item__img--ceo2 {
    height: 100px
}

.event-participants__participants__item__name,.event-participants__participants__item_-designation {
    display: block
}

.event-participants__participants__item__name {
    font-weight: bold;
    color: #000;
    margin-top: 5px
}

.event-participants__participants__item__details {
    margin: 10px 0 0
}

.event-participants__participants__item--more-text {
    display: flex;
    justify-content: center;
    align-items: center
}

.container {
width: 100%;
}

.strongReasonBx {
background-color: #ffffff;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 20px;
width: 100%;
height: 480px;
text-align: justify;
font-size: small;
transition: transform 0.3s, box-shadow 0.3s;
z-index: 1;
position: relative;
}


.strongReasonBx:hover {
transform: scale(1.1) rotate3d(0, 1, 0, -10deg);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.411);
z-index: 2;
}



/* Add a sliding animation to the reason boxes on hover */
.strongReasonBx {
animation: slideInFromLeft 0.3s ease-in-out;
transition: transform 0.3s, opacity 0.3s;
}

/* Keyframes for slideInFromLeft animation */
@keyframes slideInFromLeft {
from {
transform: translateX(-20px);
opacity: 0;
}
to {
transform: translateX(0);
opacity: 1;
}
}

/* Style the images in the reason boxes */
.strongReasonBx img {
max-width: 60px;
max-height: 60px;
}

/* Style the headings and paragraphs in the reason boxes */
.strongReasonBx .black23 {
color: #002157;
font-size: 20px;
font-weight: bold;
}

.strongReasonBx p {
color: #555;
font-size: 15px;
}

/* Add a background color and padding to the topHeading section */
.topHeading {
background-color: #f8f8f8;
padding: 20px;
border-radius: 10px;
}

/* Flex container for the reason boxes */
.strongReasonsCont {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
position: relative; /* Make sure to set the position of the container to relative */
overflow: hidden; /* Hide the overflow to ensure other boxes disappear smoothly */
}

.col-sm-4{
padding: 25px;
}


.tech-img-1{
    height: 130px;
    padding: 20px;
}
.img-card-tech-no{
    width: 170px;
    height: 200px;
}

.technologies-web-in-head{
    padding-bottom: 20px;
    color: #313979;
    font-size:40px;
    padding-bottom: 20px;
}







.technologoes-name-logos{
    height: 70px;
}

.img-card-new{
    background: #f7f7f7;
    border-radius: 8px;
    width:180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    border-radius: 8px;
    margin: 8px;
}

.img-card-new-btn{
    background: #143a7b;
    color: #fff;
    font-size: 16px;
    width: 100%;
    padding: 5px;
    text-align: center;
    margin-bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

@media screen and (max-width:540px){
    .tabs__label{
        font-size: 18px;
    }
    .tabs__input:checked+.tabs__label {
        color: #143a7b;
        border-bottom: 2px solid #143a7b;
        display: block;
        font-weight: 600;
        font-size: 19px
    }
}