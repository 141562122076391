:root {
   
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hsl(241, 61%, 46%);
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  
  .portfolio-main-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .card {
    color: var(--clr-neutral-100);
    background-image: url('../InsuImages/websitedevelopment.jpg');
    background-size: cover;
    padding: 10rem 0 0;
    width: 88vw;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
    height: 550px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .card:hover,
  .card:focus-within {
    transform: scale(1.05);
  }
  
  .card-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    --padding: 1.5rem;
    padding: var(--padding);
    height: 100%;
    background: linear-gradient(
      hsla(0, 4%, 74%, 0),
      hsl(20 0% 0% / 0.3) 20%,
      hsl(0 0% 0% / 1)
    );
  }
  
  .card-title {
    position: relative;
    width: max-content;
  }
  
  .card:hover .card-title::after,
  .card:focus-within .card-title::after {
    transform: scaleX(1);
  }
  
  .card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    width: calc(100% + var(--padding));
    left: calc(var(--padding) * -1);
    bottom: -2px;
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
  }
  
  @media (hover) {
    .card-content {
      transform: translateY(110%);
      transition: transform 500ms ease;
    }
  
    .card-content > *:not(.card-title) {
      opacity: 0;
      transition: opacity 500ms linear;
    }
  
    .card:hover .card-content > *:not(.card-title),
    .card:focus-within .card-content > *:not(.card-title) {
      opacity: 1;
      transition-delay: 700ms;
    }
  
    .card:hover .card-content,
    .card:focus-within .card-content {
      transform: translateY(0);
      transition-delay: 500ms;
    }
  
    .card:focus-within .card-content {
      transition-duration: 0ms;
    }
  
    .card-title::after {
      transform: scaleX(0);
    }
  }
  
  .button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--clr-neutral-900);
    background-color: var(--clr-accent-400);
    padding: 0.5em 1.25em;
    border-radius: 0.25rem;
    letter-spacing: 1px;
    height: 40px;
    border-radius: 8px;
    padding-top: 10px;
  }
  
  .button:hover,
  .button:focus {
    background-color: var(--clr-neutral-100);
    color: blue;
    border: 1px solid blue;
  }
  
  .card-body {
    color: rgb(255, 255, 255/0.85);
    font-size: 40px;
    padding-bottom: 40px;
    letter-spacing: 1.3px;
  }
  
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
      transition-delay: 0ms !important;
    }
  }
  
  .our-wk{
    font-size: 20px;
  }
  .portfolio-head-rote{
    font-size: 40px;
    font-family: sans-serif;
    padding-top: 10px;
  }

  .portfolio-para-rote{
    font-size: 20px;
    width: 650px;
    text-align: center;
    color: darkgrey;
    font-family: sans-serif;
    padding-bottom: 50px;
    line-height: 32px;
    padding-top: 20px;
  }
.portfolio-two-banners{
  display: flex;
  align-items: center;
}
  .card-2{
    width: 42vw;
    height: 600px;
    margin-right: 30px;
    background-image: url('../InsuImages/logodesign.jpg');
    background-size: cover;
  }

  .card-bk-img-2{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705049750/bmcet5nsi2uarlzx28gv.webp");
  }

  .card-img-3{
    background-image: url('../InsuImages/SEO.jpg');
  }

  .card-3{
    height: 450px;
  }
  .card-3-im{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705050935/krvdjblqxw25x6eyqhsm.jpg");
  }

  .card-3-im-2{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705051190/lzmu7cfr9xomeu3e2hpb.jpg");
  }
.seo-port-img-ca{
  background-image: url('../InsuImages/webdevelop.jpg');

}
.seo-port-img-ca-graphic{
  background-image: url('../InsuImages/graphicdesign.jpg');
}
  .enquire-now-text-2{
    color: black;
  }

  .button-enquire-now-container-portfolio{
    margin-top: 30px;
  }
.port-btn-con-c{
  margin-top: 20px;
}
.port-btn-con{
  color: black;
}

  @media screen and (max-width:690px){
    .new-pf-mob{
      width: 90%;
    }

  }
  @media screen and (max-width:823px){
    .card-body{
      font-size: 25px;
      text-align: center;
    }
  }
  @media screen and (max-width:540px){
    .pf-head-mob{
      font-size: 30px;
    }
    .new-pf-mob{
      font-size: 18px;
    }
    .pf-cd-mob{
      width: 90%;
      margin-right: 0px;
      height: 400px;
    }
    .portfolio-two-banners{
      flex-direction: column;
      width: 100%;
    }
    .port-btn-con{
      font-size: 18px;
      text-align: center;
    }
    
  }
  .pf-cd-mob{
  margin: 10px;
  }
  @media screen and (min-width:541px) and (max-width:767px){
    .pf-cd-mob{
      height: 300px;
      margin: 10px;
    }
  }

  @media screen and (max-width:540px){
    .seo-port-img-ca{
    width: 100%;
    }
  }









  .example {
    cursor: pointer;
    height: auto;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: 15px;
    width: 90%;
  }
  .example .fadedbox {
    background-color:  #191919;
    position: absolute;
    top: 0;
    color: #fff;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    opacity: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  align-items: center;
    margin: 0;
    overflow: hidden;
  }
  .example:hover .fadedbox {
    opacity: 0.8;
  
  }
  .example .text {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
  }
  .example .title {
    text-transform: uppercase;
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 0.3s;
  }
  .example:hover .title,
  .example:focus .title {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
  .fadedbox{
  width: 95%;
  border-radius: 15px;
  }
  .fadedbox1{
    width: 100% !important;
    border-radius: 10px;
    }
  
  .card-portfolio-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
  
  }
  .image-n{
    border-radius: 15px;
    width:95%;
     height:auto;
  }
  
  .portfolio-second-row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87%;
  }
  
  
  
  .portfolio-main-page-headings{
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    font-family: sans-serif;
    width: 100%;
  }
  .portfolio-main-page-buttons{
    font-size: 17px;
    height: 40px;
    width: 130px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #302ebd;
    cursor: pointer;
  }
  
  @media screen and (max-width:767px){
    .portfolio-second-row{
      flex-direction: column;
      width: 95%;
    }
    .portfolio-main-page-headings{
      font-size: 18px;
      font-weight: normal;
    }
    .portfolio-main-page-buttons{
      font-size: 15px;
      height: 30px;
      width: 100px;
      margin-top: -10px;
    }
  }

  @media screen and (max-width:540px){
    .image-n{
       height:250px;
    }
  }

 