@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');

.how-we-work-heading{
  color: #313979;
  font-size: 35px;
  font-weight: bold;
  font-family: sans-serif;
  padding-bottom: 28px;
  padding-top: 25px;
}

.how-we-work-heading-span{
  font-size: 15px;
  color: gray;
}

.work-heading-2{
  font-size: 23px;
  padding-bottom: 30px;
}

.how-we-work-button{
  font-size: 20px;
  color: #ffffff;
  padding: 13px;
  background: linear-gradient(to bottom, #be68e5 0%, #9330c8 61%, #9330c8 100%);
  border: none;
padding-left: 17px;
padding-right: 17px;
}



.how-we-work-main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  background-size: cover;
  padding-bottom: 40px;
}

.how-we-work-container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.how-we-work-container-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

@media screen and (max-width:767px) {
  .how-we-work-container{
    flex-direction: column;
  }
  .how-we-work-heading{
    text-align: center;
  }
  .custom-head-mob{
    line-height: 35px;
    font-size: 22px;
    padding-top: 30px;
  }
  .how-we-work-button{
    margin-top: 15px;
  }
}
.how-we-work-para{
  width: 80%;
}

@media screen and (max-width:540px){
  .how-we-work-para{
    width: 90%;
  }
}