.canva-img-container-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.canva-img-container-items-1{
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:912px){
    .graphic-mob-im{
        width: 100%;
        margin-top: 20px;
    }
}