.our-clients-main-container{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color:#E5E4E2;
    padding-top: 30px;
    padding-bottom: 30px;
}
.our-clients-slider-container-1{
	width: 100%;
}
.our-clients-slider-container{
	padding-top: 50px;
	width: 90%;
    margin-top: 0px;
    padding-top: 15px;
}

.our-clients-main-head{
	font-size: 35px;
	font-family: sans-serif;
	padding-bottom: 0px;
	margin-bottom: 0px;
}
.our-clients-image-container{
	background-color: #ffffff;
	width: 80%;
	height: 130px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.our-clients-image-container-2{
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.our-clients-img-s{
	height: 60px;
}
.our-clients-company-name{
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
	
	text-align: center;
	font-family: sans-serif;
    padding-top: 20px;
}

@media screen and (max-width:767px) {
	.our-clients-company-name{
		font-size: 16px;
		color: #313979;
	}
}