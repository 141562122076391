.seo-route-img-main{
    max-width: 60%;
}
.seo-rote-main{
    padding-bottom: 100px;
}

.seo-services-we-provide-heading{
    font-family: sans-serif;
    color: #313979;
    font-size: 35px;
    padding-bottom: 20px;
    text-align: center;
}

.seo-services-we-provide-paragraph{
    font-family: sans-serif;
    font-size: 17px;
    text-align: center;
    padding-bottom: 30px;
    width: 840px;
}

.seo-services-we-provide-timeline{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seo-services-we-provide-timeline-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}
.seo-rote-img-img{
    align-self: flex-start;
    height: 40px;
    margin-right: 20px;
}
.seo-rote-img-img-2{
    margin-left: 20px;
}
.seo-rote-main-div{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}

.tech-use-seo-rote{
    text-align: left;
    font-size: 18px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 8px;
}
.tech-use-seo-rote-para{
    font-size: 15px;
}
.seo-rote-main-div-main{
    margin-right: 0px !important;
}

.seo-new-tools-list-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    flex-wrap: wrap;
}

.seo-new-tools-list-container-item{

    margin-left: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
    width: 30%;
}


@media  screen and (min-width:541px) and (max-width:912px){
    .seo-new-tools-list-container-item{
        width: 40%;
        margin: 10px;
    }
    
}

@media  screen and (max-width:767px){
    .seo-new-tools-list-container-item{
        width: 90%;
        margin-right: 0px;
    }
    .seo-services-we-provide-heading{
        font-size: 25px;
        text-align: center;
        line-height: 35px;
    }
    .chrono-above-para-mob{
        width: 100%;
    }
}

@media screen and (max-width:539px){
    .design-mobile-view-ne{
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    .tools-all-detail-con{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    
    .tools-all-detail-con-img{
        align-self: center;
    }
    .seo-services-we-provide-timeline{
        width: 100% !important;
    }
}


@media screen and (min-width:767px) and (max-width:991px){
    .new-image-backend{
        width: 95%;
    }
}