.contact-us-main-details-container-1{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ffffff;
    min-height: 100vh;
    background-size: cover;
}

.contact-us-main-details-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 93%;
}
.hd-2-c{
    padding-bottom: 20px;
}
.hd-3-c{
    line-height: 40px;
    padding-bottom: 20px;
}
.contact-us-route-container{
    background: aliceblue;
    padding: 30px;
    width: 40%;
    height: 550px;
}


.contact-us-route-heading{
    font-family: Poppins,sans-serif;
    color: #050748;
    font-weight: 600;
    font-size: 35px;
}
.contact-us-route-address-heading{
    color: #6a6a8e;
    font-size: 22px;
    display: block;
    font-weight: 700;
    padding-top: 8px;
}

.contact-us-route-add-para{
font-size: 18px;
    line-height: 35px;
    color: #6a6a8e;
    padding-bottom: 20px;
}

.contact-us-left-con{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 20px;
}

.get-in-touch-contact-route{
    font-family: Poppins,sans-serif;
    color: #050748;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.contact-us-enter-name{
    width: 100%;
    margin-right: 25px;
    height: 40px;
    border: none;
    border-bottom: 1px solid lightgrey;
    font-family: sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
    padding-left: 10px;
    outline: none;
    border-radius: 5px;

}
.contact-us-enter-name:hover{
    border-bottom: 2px solid rgb(238, 49, 112);
}
.input-contact-us-container{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.get-in-touch-contact-us-container{
    width: 53%;
    padding-left: 35px;
}
.text-area-msg{
    margin-top: 30px;
    width: 100%;
    height: 100px;
    font-family: sans-serif;
    font-size: 18px;
 color: #6a6a8e;
 border: none;

 outline: none;
 padding-left: 10px;
 border-bottom: 1px solid lightgrey;
 border-radius: 10px;
}

.text-area-msg:hover{
    border-bottom: 2px solid rgb(238, 49, 112);
}

.i-agree-terms-para{
    font-family: sans-serif;
    font-size: 17px;
    color: #6a6a8e;
    padding-left: 10px;
}
.contact-us-checkbx{
    width: 20px;
    height: 20px;
}
.checkbox-i-agree-container{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.terms-conditions-span{
    font-weight: bold;
}

.contact-us-btn-form{
    width: 100%;
    height: 55px;
    color: #ffffff;
    background-image: linear-gradient(to right,rgb(91, 91, 194),rgb(35, 35, 184));
    border-radius: 25px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}
.hd-c-4{
    padding-bottom: 30px;
}
.we-hate-spam-para{
    font-family: sans-serif;
    font-size: 15px;
    color: #6a6a8e;
    text-align: center;
    padding-top: 10px;
}

option{
    font-weight:normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 16px;
}
.hd-2-c{
    color: #313979;
}

@media screen and (max-width:540px){
    .contact-us-main-details-container{
        flex-direction: column;
    }
    .contact-us-route-container{
        width: 100%;
    }
    .get-in-touch-contact-us-container{
        width: 90%;
        margin-top: 25px;
        margin-left: 0px;
        padding-left: 0px;
    }
    .hd-2-c{
        font-size: 25px;
        padding-bottom: 0px;
    }
    .contact-us-route-address-heading{
        font-size: 18px;
    }
    .contact-us-route-add-para{
        font-size: 16px;
        line-height: 30px;
    }
    .get-in-touch-contact-route{
        font-size: 25px;
    }
    .input-contact-us-container{
        flex-direction: column;
        margin-right: 0px;
    }
    .contact-us-enter-name{
        font-size: 16px;
        margin-top: 10px;
        margin-right: 0px;
    }
    .i-agree-terms-para{
        font-size: 16px;
    }
    .contact-us-btn-form{
        font-size: 16px;
    }
}

@media screen and (min-width:541px) and (max-width:767px){
    .hd-2-c{
        line-height: 35px;
        font-size: 25px;
    }
    .contact-us-route-container{
        width: 50%;
    }
    .contact-us-route-address-heading{
        font-size: 18px;
    }
    .contact-us-route-add-para{
        font-size: 17px;
    }
    .get-in-touch-contact-route{
        font-size: 25px;
    }
    .contact-para-m{
        line-height: 25px;
    }
    .input-contact-us-container{
        flex-direction: column;
        margin-right: 0px;
    }
    .contact-us-enter-name{
        font-size: 16px;
        margin-top: 10px;
        margin-right: 0px;
    }
    .i-agree-terms-para{
        font-size: 16px;
    }
    .contact-us-btn-form{
        font-size: 16px;
    }
}