.landing-page-ban-rou{
    align-self: center;
}
.landing-pg-develop-head{
    font-family: sans-serif;
    font-size: 30px;
    line-height: 45px;
    color: #313979;
}
.landing-pg-business-dev-para{
    font-family: sans-serif;
    font-size: 18px;
    padding-top: 25px;
}

.landing-pg-develop-cont{
    width: 80%;
}
.landing-pg-develop-cont-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.landing-pg-develop-cont-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}


.landing-pg-business-dev-para-2{
    text-align: center;
}

.elements-successful-landing-pg{
    background-color: #EAEFF2;
    width: 90%;
    padding: 20px;
}
.core-elements-landing-pg{
    font-family: sans-serif;
    font-size: 28px;
    line-height: 45px;
    color: #313979;
    text-align: center;
}

.core-elements-landing-pg-container-items{
    background-color: #ffffff;
    display: flex;
    width: 45%;
    margin: 15px;
    padding: 20px;
    border-radius: 10px;
}
.core-elements-landing-pg-container-items:hover{
    margin-top: -10px;
    transition: 1s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.core-inner-no-head{
    font-family: sans-serif;
    font-size: 35px;
    line-height: 45px;
    color: #313979;
    padding-right: 20px;
    padding-left: 10px;
}

.create-your-att-head{
    font-family: sans-serif;
    font-size: 19px;
    color: #313979;
    line-height: 30px;
    
}

.create-your-att-para{
    font-family: sans-serif;
    font-size: 15px;
}

.core-elements-landing-pg-container-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}

.landing-pg-develop-cont-1-img{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.landing-img-2-benefits{
    width: 40%;
}

.landing-pg-develop-cont-1-2{
    width: 90%;
}

@media screen and (max-width:912px){
    .landing-page-ban-rou{
        width: 100%;
    }
}

@media screen and (max-width:767px){
    .landing-pg-develop-cont-1-img{
        flex-direction: column;
    }
    .landing-img-2-benefits{
        width: 90%;
    }
    .landing-pg-develop-cont-2{
        width: 90%;
    }
    .core-elements-landing-pg-container-items{
        width: 100%;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .landing-page-ban-rou{
        width: 100%;
    }
}