.partner-with-us-route-main-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
}
.partner-with-us-route-main-con-1{
    display: flex;
    align-items: center;
    width: 85%;
    justify-content: space-between;
}
.about-partner-img{
    width: 35%;
}


.partner-route-con{
    width: 60%;
}

.partner-route-about-head{
    font-family: sans-serif;
    font-size: 40px;
    padding-bottom: 10px;

}

.partner-insu-head-c{
    font-family: sans-serif;
    font-size: 40px;
}

.head-ani-gradient-pt{
    background: linear-gradient(
        to right,
        #7953cd 20%,
        #00affa 30%,
        yellow 50%,
        #0190cd 70%,
        #764ada 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
}


@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}


.about-partner-img-2{
    order: -1;
    width: 50%;
}

.partner-route-con-2{
    width: 46%;
}

.partner-route-about-head-2{
    font-size: 20px;
    line-height: 30px;
}

.partner-with-us-route-main-con-back{
    background-color: #f5f5f5;
    padding-top: 65px;
    padding-bottom: 65px;
}


.container-route-partners{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 30px;
    width: 85%;
}

.container-route-partners-item{
    width: 23%;
    height: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    margin: 8px;
    border-radius: 10px;
    transition: 0.5s;
}

.container-route-partners-item:hover{
    background-color: rgb(52, 124, 206);
    transition: 0.5s;
    .partners-outsourc-head{
        color: #ffffff;
    }
}
.image-route-partner{
    height: 60px;
}

.partners-outsourc-head{
    font-family: sans-serif;
    font-size: 17px;
    color: #777;
    padding-top: 10px;
    font-weight: normal;
}


.partner-with-us-route-main-con-pty{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1711982965/kpkoggxsxupieqc1xwr3.png");
    box-shadow: inset 0 0 0 100vmax #007bff28;
}

.partner-with-us-route-main-con-nn{
    padding-top: 180px;
}

.about-partner-img-ref-ow{
    order: -1;
}

.head-ani-gradient-pt-cus{
    font-size: 17px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.services-container-mob-view-partner-route{
    min-height: 200px !important;
    padding: 25px;
}

.why-choose-us-list-container-mob-view-ul-l{
    width: 90%;
}


.services-img-partne{
    height: 40px;
}

.image-partner-benefit{
    height: 35px;
    margin: 15px;
}

.showcase-partner-head{
    font-family: sans-serif;
    font-size: 20px;
}

.showcase-partner-para{
    font-family: sans-serif;
    font-size: 17px;
    color: #777;
}

.benefits-partner-con{
    background-color: #ffffff;
    width: 45%;
    padding: 15px;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    display: flex;
    border-radius: 10px;
}

.benefits-partner-con-2{
    box-shadow: none;
    background-color: transparent;

}


.image-con-blue-col{
    height: 70px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0000aa;
    margin-right: 25px;
    border-radius: 10px;
}

.container-route-partners-ps-a{
    width: 90%;
}

.image-partner-benefit-trns{
    height: 40px;
}

.partners-client-images-con{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 75%;
    padding-top: 30px;
    justify-content: center;
}

.para-partners-route{
    font-family: sans-serif;
    font-size: 12px;
    color: #777;
}

.para-partners-route-img{
    width: 100%;
}

.image-para-route-partn{
    width: 20%;
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.partner-insu-head-c-2{
    padding-bottom: 25px;
}

.image-para-route-partn-black{
    background-color: black;
    padding: 20px;
}
.benefits-partner-con-m-color{
    transition: 0.5s;
}
.benefits-partner-con-m-color:hover{
    background-color: #0080FE;
    transition: 0.5s;
    .showcase-partner-head-m{
        color: #ffffff;
    }
    .showcase-partner-para{
        color: #ffffff;
    }
}

@media screen and (min-width:992px) and (max-width:1200px){
    .partner-with-us-route-main-con-1{
        width: 92%;
    }
    .partner-insu-head-c-ne-n{
        padding-bottom: 20px;
    }
    .container-route-partners-ps-a{
        width: 98%;
    }
    .partners-client-images-con{
        width: 95%;
    }
    .image-para-route-partn{
        width: 18%;
    }
}

@media screen and (min-width:767px) and (max-width:991px){
    .partner-with-us-route-main-con-1{
        flex-direction: column;
        justify-content: center;
        width: 70%;
    }
    .partner-route-con{
        width: 100%;
    }
    .about-partner-img{
        width: 100%;
    }
    .container-route-partners-item{
        width: 40%;
        margin: 10px;
    }
    .benefits-partner-con{
        width: 100%;
    }
}

@media screen and (max-width:766px){
    .partner-with-us-route-main-con-1{
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }
    .partner-route-con{
        width: 100%;
    }
    .about-partner-img{
        width: 100%;
    }
    .container-route-partners-item{
        width: 100%;
        margin: 0px;
        margin-bottom: 10px;
    }
    .benefits-partner-con{
        width: 100%;
    }
    .careers-rote-img-partners{
        margin-top: 80px;
    }
    .career-mob-con-partners{
        align-self: center;
        width: 90%;
        padding: 20px;
    }
    .partner-route-about-head{
        font-size: 30px;
        text-align: center;
        line-height: 35px;
        padding-bottom: 10px;
    }
    .partner-insu-head-c-ne-n{
        font-size: 30px;
        text-align: center;
        line-height: 35px;
        padding-bottom: 10px;
    }
    .partner-route-about-head-2{
        font-size: 21px;
        text-align: center;
        line-height: 35px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .partner-insu-head-c{
        font-size: 27px;
        text-align: center;
        line-height: 35px;
        padding-bottom: 10px;
    }
    .partner-with-us-route-main-con-nn{
        padding-top: 50px;
    }
    .partner-route-about-head{
        padding-top: 10px;
    }
   
    .benefits-partner-con-m{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }
    .image-partner-benefit-m{
        width: 60px;
        height: 60px;
    }
    .showcase-partner-head-m{
        text-align: center;
    }
    .container-route-partners{
        width: 95%;
    }
   .image-con-blue-col{
    height: 50px;
    width: 50px;
    margin: 0px;
    margin-top: 15px;
    margin-right: 12px;

   }
   .image-partner-benefit-trns{
    height: 30px;
    width: 30px;
   }
   .benefits-partner-con-2-mob{
    padding: 0px;
   }
   .partners-client-images-con{
    width: 90%;
   }
    .image-para-route-partn{
        width: 40%;
        margin: 10px;

    }
}