.flutter-business-dev-main-1{
    width: 85%;
}

@media screen and (max-width:912px){
    .flutter-mob-img{
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (min-width:768px) and  (max-width:991px){
    .react-native-business-dev-main-1-nnn{
        flex-direction: column;
    }
    .react-native-business-dev-main-1-nnn-img{
        width: 100%;
    }
}