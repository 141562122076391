.graphic-design-ty-list-items{
    border-radius: 10px;
}

.get-what-you-pay{
    color: #313979;
    font-family: sans-serif;
    font-size: 20px;
}

.whytrustandchooseus-head{
    font-family: sans-serif;
    font-size: 40px;
    width: 90%;
    color: #313979;
    text-align: center;
    padding-bottom: 10px;
}

.get-what-you-pay-para{
    font-size: 17px;
    font-family: sans-serif;
}
.get-what-you-pay-div{
    margin-top: 15px;
    width: 90%;
}

.web-dev-how-we-2{
    width: 750px;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 0px;
}
.web-dev-how-we-2-para{
    font-family: sans-serif;
    font-size: 17px;
}

.qualified-reso-con{
    width: 45%;
}

.qualified-resou-head{
    font-family: sans-serif;
    font-size: 28px;
    color: #313979;
    padding-bottom: 8px;
    padding-top: 15px;
}

.qualified-resou-para{
    font-family: sans-serif;
    font-size: 18px;
}

.why-trust-and-choose-main-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.why-trust-and-choose-container{
    margin-top: 50px;
}
.why-choose-us-tr-img{
    width: 50%;
}

.why-choose-us-tr-img-2{
    order: -1;
    width: 45%;
}

.why-trust-and-choose-container-2{
    margin-top: 100px;
}
.why-trust-us-list-of-te{
margin-bottom: 150px;
}


@media screen and (max-width:912px){
    .transform-main-cont{
        flex-direction: column;
    }
    .business-pre-img{
        width: 90%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .graphic-route-ty-item-con-mobile-view{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .why-trust-us-mobile-head{
        font-size: 25px;
        line-height: 36px;
        text-align: center;
    }
    .why-trust-us-mobile-item-right{
        font-size: 17px;
        margin-left: 0px;
        padding-left: 0px;
    }
    .why-trust-request-a-quote-mob{
        width: 180px;
        font-size: 15px;
    }
    .no-transform-button-container{
        flex-direction: column;
        margin-top: 20px;
    }
    .passionate-mob-head{
        width: 90%;
        font-size: 25px;
        line-height: 35px;
    }
    .passionate-mob-head-col{
        font-size: 17px;
    }
    .graphic-route-ty-item-con-mobile{
        width: 99%;
    }
    .mobile-why-trust-us-logo{
        width: 50px;
        height: 50px;
        margin-bottom: 25px;
    }
    .graphic-design-ty-list-items-div{
        text-align: center;
        margin-left: 0px;
    }
    .money-back-mobile-con{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .mobile-cost-back-img{
        width: 80%;
        margin-top: 20px;
    }
    .we-give-money-back-mobile{
        width: 98%;
    }
    .we-give-money-back-mobile-head{
        width: 90%;
        font-size: 28px;
        text-align: center;
        line-height: 38px;
    }
    .get-what-you-pay{
        line-height: 30px;
        padding-bottom: 15px;
    }
    .why-trust-us-webing-mob{
        width: 88%;
    }
    .why-trust-and-choose-container{
        flex-direction: column;
    }
    .qualified-reso-con{
        width: 100%;
    }
    .qualified-resou-head{
        text-align: center;
    }
    .qualified-mob-img{
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (max-width:539px){
    .why-trust-us-webing-mob{
        font-size: 27px;
        line-height: 38px;
    }
    .whytrustandchooseus-head{
        font-size: 25px;
        line-height: 35px;
    }
}