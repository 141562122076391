.payment-policy-route-main-con{
    background: linear-gradient(135deg,#3F74A3,#0000AA 32%,#4D4DFF);
    background-size: cover;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-policy-route-heading{
    font-family: sans-serif;
    font-size: 35px;
    color: #ffffff;
}

.payment-policy-route-main-con-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 100px;
    align-items: center;
}
.payment-policy-route-con-2{
    display: flex;
    justify-content: center;
    width: 85%;
    flex-direction: column;
}

.payment-policy-route-heading-2{
    font-family: sans-serif;
    font-size: 40px;
    text-align: center;
    padding-bottom: 10px;

}

.payment-policy-route-heading-3{
    font-family: sans-serif;
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 5px;
}

.payment-policy-route-heading-para{
    font-family: sans-serif;
    font-size: 17px;
    line-height: 29px;
}

.due-dste-span{
    font-size: 18px;
    font-weight: bold;
}


@media screen and (max-width:600px){
    .payment-policy-route-heading-2{
        font-size: 25px;
        line-height: 35px;
        padding-bottom: 15px;
    }
    .payment-policy-route-heading{
        font-size: 25px;
        text-align: center;
        line-height: 30px;
        padding-top: 40px;

    }
    .payment-policy-route-heading-3{
        padding-bottom: 10px;
        font-size: 20px;
    }
}