.complete-flow-of-work-head{
    color: #313979;
    font-family: sans-serif;
    font-size: 37px;
}
.how-we-work-rote-img{
    height: 60px;
}
.complete-flow-of-work-inner-head{
    color: #313979;
    font-family: sans-serif;
    font-size: 20px;
    padding-top: 10px;
    
}


.complete-flow-of-work-inner-para{
    font-size: 18px;
    font-family: sans-serif;
    line-height: 30px;
}

.complete-flow-of-work-inner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 370px;
    background-color: #ffffff;
    border: 2px solid aliceblue;
    padding: 15px;
  box-shadow: 5px 5px 10px #e1e1e1;
  border-bottom-right-radius: 4em;
  border-top-left-radius: 4em;
  background: linear-gradient(to left, #ffffff 50%, #0080FE 50%);
  background-size: 220%;
  background-position: right;
  transition: background-position 0.5s ease-out;
  margin-right: 27px;
}

.complete-flow-of-work-inner-container:hover {
    background-position: left;
    .complete-flow-of-work-inner-head{
        color: #ffffff;
        font-weight: bold;
    }
    .complete-flow-of-work-inner-para{
        color: #ffffff;
    }
  }

  .complete-flow-of-work-inner-container-main{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .complete-div-con{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }
  .complete-flow-of-work-inner-container-main-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342160/yfdabizwm350mqba1gbs.jpg");
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .curve-line-how-we-work{
    width: 67%;
    height: 150px;
    align-self: flex-start;
    padding-left: 15px;
  }

  .sid-2{
    background-color: #ffffff;
    background-image: none;
  }

  .web-dev-how-we{
    color: #313979;
    padding-bottom: 20px;
  }

  .preparation-brief-head{
    font-family: sans-serif;
    font-size: 17px;
    color: #313979;
    line-height: 25px;
  }

  .preparation-brief-div{
    display: flex;
    flex-direction: column;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517116/vpmiexjjby4k30ut2mjr.png");
    background-size: cover;
    align-items: center;
    width: 18%;
    text-align: center;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    height: 250px;
  }
.preparation-brief-div-main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .preparation-brief-para{
    font-size: 16px;
    padding-top: 10px;
  }

  .arrow-preap{
    height: 40px;
    margin-top: 10px;
  }

  .transform-col-pa{
    font-size: 18px;
    font-family: sans-serif;
  }
.transform-col-pa-2{
  padding-top: 25px;
}
  .img-preap-2{
    height: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .preparation-brief-div-back-2{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518342/arx2uq6qncblx6luinsh.jpg");
  }
  .all-our-sites-para{
    text-align: center;
    padding-top: 50px;
    font-family: sans-serif;
    font-size: 18px;
    width: 85%;
    padding-bottom: 40px;
  }

  .enquire-now-text-2{
    color: black;
    padding-top: 20px;
  }

  .graphic-process-bg{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1706519634/hnmjrysc3nhfco5qmokc.jpg");
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right-how-we-img{
    height: 30px;
    margin-right: 10px;
  }
  .right-how-we-para{
    font-family: sans-serif;
    font-size: 18px;
    width: 350px;
  }
  .bulb-img-how{
    width: 35%;
  }

  .bulb-how-we-work-con{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .graphic-arrow-main-container{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .bulb-how-we-work-con-2{
    width: 55%;
  }

  .how-we-arrow-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .how-we-arrow-list-item{
    margin-top: 20px;
    width: 300px;
  }

  .cross-arrow-2-si{
    align-self: flex-start;
    height: 200px;
    width: 50%;
    margin-left: 20px;
  }

  .research-img-how{
    order: -1;
  }
  .how-we-arrow-list-2{
    flex-direction: column;
    width: 100%;
  }

  .list-transform-2{
    width: 400px;
  }

  .designers-may-head{
    padding-top: 20px;
    font-family: sans-serif;
    font-size: 18px;
  }


  .preparation-brief-div-3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.fourth-img-con-mobile{
  display: none;
}
.preparation-brief-div-back-2-hide-1{
  display: none;
}
.how-we-work-in-insu-img{
  margin-right: 25px;
  border-radius: 10px;
  width: 40%;
}
  @media screen and (max-width:991px){
    .using-empowered-mob-head{
      font-size: 32px;
      line-height: 41px;
      text-align: center;
    }
    .how-we-work-mob-im{
      width: 100%;
    }
    .how-we-work-2-insu{
      flex-direction: column;
    }
    .how-we-work-2-content-img{
      width: 100%;
      order: 1;
      margin-top: 30px;
    }
    .how-we-work-2-insu-3{
      width: 100%;
    }
    .how-we-work-3-insu-img{
      width: 100%;
      margin-top: 30px;
      
    }
    .how-we-work-2-insu-3-con{
      width: 90%;
      font-size: 30px;
    }
    .how-we-work-2-insu-3-con-para{
      width: 100%;
    }
    .curve-line-how-we-work{
      display: none;
    }
    .complete-flow-of-work-inner-container{
      width: 40%;
      margin-top: 25px;
      height: fit-content;
    }
    .preparation-mobile-v{
      width: 90%;
    }
    .preparation-brief-div-main-mob-prep{
      flex-wrap: wrap;
      width: 90%;
    }
    .preparation-brief-div-small-content-mobile{
      width: 30%;
    }
    .preparation-brief-div-back-2-hide-1{
      display: block;
    }
    .bulb-how-we-work-con{
      flex-direction: column;
      width: 90%;
      margin-bottom: 35px;
    }
    .bulb-how-we-work-con-2{
      width: 100%;
    }
    .bulb-img-how{
      width: 80%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .cross-arrow-2-si{
      display: none;
    }
    .using-empowered-mob-head{
      max-width: 90%;
      font-size: 30px !important;
    }
    .how-2-we-work-mob-im-hww{
      width: 100%;
    }
  }


  @media screen and (min-width:768px) and (max-width:821px){
    .fourth-img-con-mobile{
      display: block;
    }
    .fourth-img-con-mobile-hide{
      display: none;
    }
  }

  @media screen and (max-width:767px){
    .complete-flow-of-work-inner-container{
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-right: 0px;
    }
  }
.black-para{
  color: black;
  font-size: 20px;
}
.want-black-div{
  margin-top: 20px;
}
  @media screen and (min-width:540px) and (max-width:767px) {
    .preparation-brief-div-small-content-mobile{
      width: 40%;
    }
    .web-dev-how-we{
      font-size: 25px;
      text-align: center;
      line-height: 30px;
    }
  }
  @media screen and (max-width:539px){
    .preparation-brief-div-small-content-mobile{
      width: 100%;
    }
    .using-empowered-mob-head{
      font-size: 28px;
    }
    .complete-flow-of-work-head{
      font-size: 25px;
      text-align: center;
      width: 90%;
      line-height: 32px;
      padding-top: 10px;
    }
    .web-dev-how-we{
      font-size: 25px;
      text-align: center;
      text-align: center;
      line-height: 40px;
    }
    .how-we-work-in-insu-img{
      width: 90%;
    }
    .how-we-work-2-insu-3-con{
      text-align: center;
      font-size: 25px;
      line-height: 35px;
      padding-top: 15px;
    }
  }