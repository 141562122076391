.Count {
    padding: 2rem;
    margin: 1rem;
    border-radius: 2em;
    box-shadow: 1px 2px 2px #0D3B66;
    background-color: #ffffff;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0D3B66;

}

.image-count{
    height: 40px;
    background-color: transparent;
}
.count-increase-head{
    padding-left: 10px;
    font-family: sans-serif;
    font-size: 30px;
    color: #ffffff;
}

.count-main-con{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 380px;
    flex-shrink: 1;
}
.label-count-container{
    font-family: sans-serif;
    font-size: 20px;
    color: #ffffff;
    
}

.count-main-container-increase{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.count-main-container-1-increase{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}



.count-image{
    height: 40px;
}

.count-image-container{
    background-color: #ffffff;
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 15px;
}

.count-heading{
    font-family: sans-serif;
    font-size: 35px;
    color: #ffffff;
}

.count-paragraph{
    font-family: sans-serif;
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
}

.count-image-content-container{
    display: flex;
    align-items: center;
}

.count-image-content-container-main{
    display: flex ;
    flex-direction: row ;
    align-items: center;
    justify-content: space-between;
    width: 24%;
}

@media screen and (max-width:767px) {
    .count-image-content-container-main{
        width: 100%;
        padding: 20px;
        justify-content: center;
    }
    .count-heading{
        font-size: 32px;
    }
}

@media screen and (min-width:768px) and (max-width:912px) {
    .count-image-content-container-main{
        width: 50%;
    }
}