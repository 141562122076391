@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');
.cont4{
    color: var(--cyan);
    background-color: var(--main);
    border:none;
    border-radius: 10px;
  font-size: 15px;
  text-shadow: var(--cyan) 0px 0px 20px;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ;
}

.cont4:focus {
  color: var(--cyan);
  outline: none;
}
.cont4:hover {
border: 3px solid #BCDFE1;
transition:0.1s;
}

.cont4 :focus::-webkit-input-placeholder {
  color:transparent;
}

.image{
  height: 55px;
}

.image-tech-main-ceontainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 30px;
    width: 80%;
  }
  
  .image-tech-container {
    height: 100px;
    width: 100px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 5px 0px;
    border-radius: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .tech-name-logo {
    height: 50px;
  }

  .mob-view-tech-logos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }