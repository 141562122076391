.container-product-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 98%;
  }
  .container-product-main-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .app-portfolio-mobiles{
    width: 80%;
  }
  .app-portfolio-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 26%;
  }
  .app-portfolio-div-head{
    font-size: 25px;
    color: #313979;
    text-align: center;
    font-family: sans-serif;
  }
  
  @media screen and (min-width:541px) and (max-width:767px){
    .app-portfolio-div{
      width: 40%;
    }
  }
  
  @media screen and (max-width:540px){
    .app-portfolio-div{
      width: 100%;
    }
  }