.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    background-color: #ffffff;
    padding-bottom: 30px;
  }
  
  .content-heading {
    font-family: sans-serif;
    font-size: 38px;
    color: #313979;
    font-weight: bold;
    padding-bottom: 10px;
  }
  
  .content-paragraph {
    font-family: sans-serif;  
    font-size: 20px;
    color: #464a69;
    line-height: 30px;
    padding-bottom: 18px;
    padding-top: 10px;
  }
  .two {
    font-size: 30px;
  }
  .content-list-container {
    padding-left: 0px;
    list-style-type: none;
  }
  
  .content-list-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .right-check {
    margin-right: 10px;
    height: 30px;
  }
  .content-item-paragraph {
    font-family: sans-serif;
    font-size: 17px;
    color: #534c4c;
  }
  
  .webingo-content-img {
    width: 45%;
  }

  .content-head{
    width: 55%;
    margin-left: 30px;
  }

  .view-portfolio-btn {
    font-family: sans-serif;
    font-size: 20px;
    color: #ffffff;
    background-color: #311edb;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    margin-top: 20px;
  }

  @media screen and (max-width:912px) {
    .content-container{
      flex-direction: column;
    }
    .content-head{
      width: 100%;
      padding-right: 20px;
    }
    .content-heading{
      font-size: 25px;
      text-align: center;
      line-height: 38px;
    }
    .content-paragraph{
      font-size: 19px;
      text-align: center;
    }
    .two{
      font-size: 20px;
    }
    .webingo-content-img{
      width: 100%;
    }
  }
