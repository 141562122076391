.container-site-map-main{
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1712072010/fvilci2mb9ybigzefmtl.jpg");
    background-size: cover;
    height: 320px;
}
.container-site-map-main-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.site-map-main-heading{
    font-family: sans-serif;
    font-size: 40px;
    text-align: center;
    text-decoration: underline;
}

.container-site-map-main-2{
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.link-site-map-route{
    color: rgba(0, 0, 0, 0.76);
}

.link-site-map-route:hover{
    color: rgb(33, 127, 216);
}

.heading-site-map-ul{
    font-size: 20px;
    line-height: 30px;
}

.transform-right-list-container-site-map{
    width: 30%;
    padding-top: 30px;
}

.conatiner-2-site-ul{
    width: 30%;
}

.transform-right-list-container-site-map-2{
    width: 100%;
    padding-top: 30px;
}


.container-site-map-main-2-main{
    padding-top: 50px;

}

.container-site-map-main-2-main-main{
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}

@media screen and (min-width:767px) and (max-width:915px){
    .container-site-map-main-2{
        flex-direction: row;
    }
    .transform-right-list-container-site-map{
        width: 48%;
    }
    .conatiner-2-site-ul{
        width: 48%;
    }
}

@media screen and (max-width:766px){
    .container-site-map-main-2{
        flex-direction: row;
    }
    .transform-right-list-container-site-map{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .conatiner-2-site-ul{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}