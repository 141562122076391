.vision-and-mission-container{
    background-color: navy;
    display: flex;
    align-items: center;
}
.vision-and-mission-img{
    width: 45%;
}
.vision-and-mission-heading{
    font-family: sans-serif;
    font-size: 38px;
    color: #ffffff;
    font-weight: bold;
}
.vision-and-mission-paragraph{
    font-family: sans-serif;
    font-size: 20px;
    color: #ffffff;
    min-width: 48px;
    line-height: 33px;
    padding-top: 15px;
}

.vision-and-mission-container-details{
    margin-left: 50px;
    margin-right: 50px;
}

@media screen and (max-width:912px){
    .vision-and-mission-container{
        flex-direction: column;
        margin: 20px;
    }
    .vision-and-mission-img{
        order: -1;
        width: 100%;
    }
    .vision-and-mission-container-details{
        width: 90%;
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .vision-and-mission-heading{
        font-size: 25px;
        line-height: 35px;
    }
    .vision-and-mission-paragraph{
        font-size: 16px;
        line-height: 25px;
    }
}