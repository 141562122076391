.results-partnersship-heading{
    color: #313979;
    font-size: 40px;
    font-family: sans-serif;
}
.results-partnersship-para{
    font-family: sans-serif;
    font-size: 18px;
    padding-top: 10px;
}
.results-partnersship-container{
    width: 60%;
}
.our-partners-image{
    width: 35%;
}

.results-partnersship-container-1{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.results-partnersship-container-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}
.our-partners-image-1{
    width: 35%;
    order: -1;
}
.results-partnersship-para-2{
    padding-top: 20px;
}
.hori-line{
    height: 10px;
    background-color: #313979;
    width: 100px;
    margin-bottom: 15px;
}

.information-partners-container{
    background: linear-gradient(135deg,#3F74A3,#0000AA 32%,#4D4DFF);
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.information-partners-heading{
    font-family: sans-serif;
    font-size: 40px;
    color: #ffffff;
    text-align: center;
}

.information-partners-para{
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 20px;
    
}

.information-partners-container-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
    flex-wrap: wrap;
    width: 90%;
}
.partners-img{
    width: 20%;
    margin: 10px;
    margin-bottom: 40px;
}

@media screen and (max-width:912px){
    .results-partnersship-container-1{
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }
    .results-partnersship-container{
        width: 100%;
        padding-top: 25px;
    }
    .our-partners-image{
        width: 100%;
    }
    .our-partners-image-1{
        width: 100%;
        order: 1;
    }
}

@media screen and (max-width:767px){
    .partners-img{
        width: 40%;
    }
    .information-partners-para{
        width: 90%;
    }
    .information-partners-heading{
        text-align: center;
    }
}
