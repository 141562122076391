.react-native-dev-image{
    align-self: center;
    width: 50%;
}

.react-native-mid-img{
    width: 40%;
}
.react-native-business-dev{
    font-family: sans-serif;
    font-size: 35px;
    line-height: 45px;
    color: #313979;
}
.react-native-business-dev-para{
    font-family: sans-serif;
    font-size: 18px;
    padding-top: 25px;
}

.react-native-business-dev-main-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.react-native-business-dev-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}




@media screen and (max-width:912px){
    .react-native-dev-image{
        width: 100%;
        margin-top: 20px;
    }

    .react-native-business-dev-main-1{
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
    .react-native-mid-img-mob{
        order: 1;
        width: 100%;
    }
   .react-native-mobile-vie{
    width: 90%;
   }
}

@media screen and (max-width:540px){
    .react-native-business-dev{
        font-size: 25px;
        line-height: 40px;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .react-native-business-dev-main-1{
        flex-direction: column;
        justify-content: center;
    }
    .react-native-mid-img-mob-wid{
        width: 100%;
    }
    .eact-native-business-dev{
        font-size: 30px;
        line-height: 38px;
    }
}