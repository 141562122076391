.website-design-w{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 95%;
}
.web-des-w{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
}
.wrapper {
  display: flex;
  width: 80%;
  justify-content: space-around;
}

.card-w {
  height: 380px;
  width: 30%;
  background-size: cover !important;
  border-radius: 15px;
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  margin-right: 15px;
  margin-bottom: 15px;
}
.card-w:hover {
  transform: translateY(20px);
}
.card-w:hover:before {
  opacity: 1;
}
.card-w:hover .info-w {
  opacity: 1;
  transform: translateY(0px);
}
.card-w:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}
.card-w img {
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
 .info-w {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  height: 250px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 250px;
  transform: translateY(30px);
  transition: 0.5s;
}
.card-w .info-w h1 {
  margin: 0px;
}
.card-w .info-w p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
.card-w .info-w button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
  margin-top: 15px;
}
.card-w .info-w button:hover {
  background: dodgerblue;
  color: white;
  width: 80px;
}

.web-work-w{
    font-size: 18px;
}

.web-des-w-page{
    width: 850px;
}

.mountain-head{
    font-size: 30px;
    color: #ffffff;
    font-family: sans-serif;
    line-height: 25px;
    padding-bottom: 10px;
}


@media screen and (max-width:912px){
  .mob-web-port-v{
    font-size: 25px;
    text-align: center;
    line-height: 35px;
  }
  .mob-web-port-v-para{
    width: 90%;
    font-size: 18px;
  }
}

@media screen and (min-width:541px) and (max-width:767px) {
  .mob-web-port-v-con{
    width: 40%;
    margin: 10px;
    height: 350px;
  }
}

@media screen and (max-width:540px) {
  .mob-web-port-v-con{
   width: 90%;
    margin: 10px;
  }
}


.web-img{
  width: 300px;
}

.website-portfolio-chn-container-mm{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  flex-direction: column;
  padding-bottom: 60px;
}



.website-portfolio-chn-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  flex-wrap: wrap;

}










.container-chn-n {
  position: relative;
  width: 31%;
  margin: 10px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.image-chn-n {
  display: block;
  width: 100%;
  transition: 0.5s;
  height: auto;
  border-radius: 10px;

}

.overlay-chn-n {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s;
  border-radius: 10px;
  background-color: #000000b0;
}



.text-chn-nn {
  color: white;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.container-chn-n:hover  {
  transition: 0.5s;
  margin-bottom: -15px;
  .image-chn-n{
    margin-bottom: -15px;
    transition: 0.5s;

  }
  .overlay-chn-n{
    opacity: 1;
    height: 104%;
    transition: 0.5s;
  }
}

.bitm-headi{
  font-family: sans-serif;
  font-size: 33px;
  color: #ffffff;
  font-weight: bold;
  padding-left: 30px;
  text-align: left;

}

.bitm-headi-web{
  font-family: sans-serif;
  font-size: 17px;
  color: #ffffff;
  padding-left: 30px;
  text-align: left;
  padding-bottom: 40px;
}
@media screen and (min-width:541px) and (max-width:767px){
  .container-chn-n{
    width: 45%;
  }
  .bitm-headi{
    font-size: 30px;
  }
}
@media screen and (max-width:540px){
  .container-chn-n{
    width: 95%;
  }
  .website-portfolio-chn-container{
    flex-direction: column;

  }
}
