.about-route-image {
    width: 23%;
}
.about-route-container {
    background-image: linear-gradient(
        to right,
        rgb(89, 89, 253),
        #0006b1,
        #0013de,
        rgb(26, 26, 202)
    );
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.about-count-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    border: none !important;
}
.about-count-div-head {
    color: #ffffff;
    font-weight: bold;
    padding-bottom: 15px;
    font-size: 23px !important;
    padding-top: 0px;
    margin-top: 0px;
    text-align: center;
}
.count-about-div-pa {
    color: #ffffff;
    font-size: 17px !important;
    line-height: 25px !important;
    letter-spacing: 0.5px !important;
}

.form-container-about-route {
    background-color: #ffffff;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px;
    width: 27%;
    border-radius: 15px;
    padding-bottom: 20px;
}
.form-container-head {
    font-family: sans-serif;
    font-size: 23px;
}

.form-container-details {
    width: 80%;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
    font-family: sans-serif;
    font-size: 13px;
    color: black;
    margin-bottom: 10px;
}
.form-container-details:hover {
    border-bottom: 1px solid blue;
}
.form-select-op {
    width: 80%;
    height: 35px;
    border: none;
    border-bottom: 1px solid lightgray;
    font-family: sans-serif;
    font-size: 19px;
    color: black;
    margin-bottom: 10px;
    padding-bottom: 8px;
}
.button-submit-about-route {
    width: 80%;
    height: 40px;
    border: none;
    font-family: sans-serif;
    font-size: 15px;
    color: #ffffff;
    background-color: blue;
    border-radius: 5px;
    margin-top: 15px;
}

@media screen and (max-width: 912px) {
    .about-route-container {
        flex-direction: column;
        padding-top: 140px;
        padding-bottom: 35px;
    }
    .about-route-image {
        width: 60%;
    }
    .mobile-details-rote-cont {
        width: 80%;
    }
    .form-container-about-route {
        width: 80%;
    }
    .about-count-div {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 540px) {
    .about-count-div-head {
        font-size: 20px !important;
    }
}

option {
    font-size: 15px;
}
