@import url("https://fonts.googleapis.com/css?family=Questrial");
.box-l{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 80px;
    justify-content: center;
}
.l-1{
    display: flex;
    flex-direction: column;
}
.logo-design-head-con-l{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
}
.logo-des-p-2{
    width: 900px;
}
#card-container-l {
  position: relative;
  width: 22%;
  height: 300px;
  margin: 5px;
  z-index: 1;
  float: left;
  perspective: 1000px;
}

.img-l {
  width: 100%;
}

#card-l, #card2-l, #card3-l {
  width: 100%;
  height: 98%;
  transform-style: preserve-3d;
  transition: all 0.8s linear;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}

#card-l:hover {
  transform: rotateY(180deg);
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
}

#card2-l:hover {
  transform: rotateY(-180deg);
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
}

#card3-l:hover {
  transform: rotateX(180deg);
  box-shadow: 5px -5px 15px rgba(0, 0, 0, 0.3);
}
#card3-l .back {
  transform: rotateX(-180deg);
}

.face {
  position: absolute;
  width: 100%;
  height: fit-content;
  backface-visibility: hidden;
}

.back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  opacity: 0.7;
  height: fit-content;
}

.roses-l {
  font-family: "Questrial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
}

.roses-l {
  font-size: 20px;
  padding-top: 15px;
  color: #DD899E;
}

.roses-l {
  font-size: 20px;
}

.date-l {
  color: #D0B2AF;
}

.flip-card {
  background-color: transparent;
  width: 22%;
  margin: 10px;
}

@media screen and (max-width:540px){
  .flip-card{
    width: 100%;
  }
}

@media screen and (min-width:541px) and (max-width:767px){
  .flip-card{
    width: 30%;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: relative;
  width: 100%;
 
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
 
}
.logo-design-main-page-route{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 80px;
  margin-bottom: 80px;
}
.logo-design-main-page-route-list{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (min-width:767px) and (max-width:985px){
  #card-container-l{
    width: 30%;
    height: 300px;
  }
}

@media screen and (min-width:541px) and (max-width:767px){
  #card-container-l{
    width: 40%;
    height: 300px;
  }
}

@media screen and (max-width:540px){
  #card-container-l{
    width: 90%;
    height: 350px;
    margin: 10px;
  }
}

.new-head-ldv{
  font-size: 30px;
  line-height: 40px;
}


.logo-page-route-list-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
}

.logo-page-route-imag {
  --s: 15px; /* size of the frame */
  --b: 2px; /* border thickness */
  --w: 280px; /* width of the image */
  --c: lightgrey;
   margin: 5px;
  width: var(--w);
  aspect-ratio: 1;
  object-fit: cover;
  padding: calc(2 * var(--s));
  --_g: var(--c) var(--b), #1754d800 0 calc(100% - var(--b)), var(--c) 0;
  background: linear-gradient(var(--_g)) 50%/100% var(--_i, 100%) no-repeat,
    linear-gradient(90deg, var(--_g)) 50% / var(--_i, 100%) 100% no-repeat;
  outline: calc(var(--w) / 2) solid transparent;
  outline-offset: calc(var(--w) / -2 - 2 * var(--s));
  transition: 0.4s;
  cursor: pointer;
}
.logo-page-route-imag:hover {
  outline: var(--b) solid var(--c);
  outline-offset: calc(var(--s) / -2);
  --_i: calc(100% - 2 * var(--s));
}



@media screen and (max-width:539px){
  .logo-page-route-imag {
    --w:330px;
  }
}

@media screen and (min-width:540px) and (max-width:610px){
  .logo-page-route-imag {
    --w:250px;
  }
}