.why-choose-us-container {
  /*background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1704536677/zvinzzlqhw8swbkvtnpp.jpg");*/
    background: linear-gradient(135deg,#3F74A3,#0000AA 32%,#4D4DFF);
    background-size: cover;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 75px;
    padding-bottom: 40px;
  }
  
  .why-choose-us-heading {
    font-family: 'Roboto';
    font-size: 18px;
    color: hwb(182 16% 9%);
    text-align: center;
    line-height: 30px;
  }
  
  .why-we-deliver {
    font-family: sans-serif;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .why-choose-us-description {
    color: #ffffff;
    font-family: sans-serif;
    font-size: 18px;
    padding-bottom: 20px;
    text-align: center;
  }
  
  .services-heading {
    font-family: sans-serif;
    font-size: 23px;
    color: black;
    line-height: 35px;
    
  }
  .services-img {
    height: 53px;
    margin-right: 10px;
  }
  .services-img-i-2{
    height: 40px;
  }
  .services-paragraph {
    font-family: sans-serif;
    font-size: 17px;
    padding-top: 10px;
  }
  .why-choose-us-list-container {
    padding-left: 0px;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }
  .services-container {
    background-color: #ffffff;
    background-size: cover;
    width: 30%;
    margin-right: 25px;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    min-height: 310px;
    max-height: fit-content;
    justify-content: center;

  }
  
  .button-enquire-now-container {
    display: flex;
    align-items: center;
  }
  
  .enquire-now-text {
    font-family: sans-serif;
    font-size: 30px;
    color: #ffffff;
    padding-right: 50px;
  }
  
  .start-span {
    font-weight: bold;
  }
  .services-img-2{
    height: 55px;
    margin-right: 15px;
  }
.want-enquire-new-btn{
  display: flex;
  align-items: center;
}
.want-enquire-new-btn-para{
  font-family: sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.want-enquire-new-btn-span{
  font-weight: bold;
}


.new-want-btn {
  flex: 1 1 auto;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  height: 60px;
  width: 150px;
  font-family: sans-serif;
  border: none;
  margin-left: 15px;
 }


.new-want-btn:hover {
  background-position: right center; 
  cursor: pointer;
}
.new-want-btn-1 {
  background-image: linear-gradient(to right, #7270f8 0%, #554cce 51%, #5128e6 100%);
}

.new-insu-btn-want-makes{
  font-size: 25px;
}
  @media screen and (max-width:767px) {
    .why-choose-us-list-container-mob-view{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .services-container-mob-view{
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0px;
      padding: 0pxs;
    }
    .enquire-now-text-2-mob-view-why-choose-us{
      font-size: 18px;
      padding-right: 0px;
      margin-right: 0px;
      padding-top: 20px;
      text-align: center;
    }
    .enquire-mob-v-para{
      height: 60px;
      width: 150px;
      line-height: 30px;
      border-radius: 0px;
      padding: 0px;
      font-size: 18px;
      margin: 0px;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .why-we-deliver-mob-view{
      line-height: 35px;
      font-size: 25px;
      text-align: center;
    }
    .services-heading{
      text-align: center;
      font-size: 20px;
      padding-top: 20px;
    }
    .services-paragraph{
      text-align: center;
    }
  }

  @media screen and (min-width:768px) and (max-width:985px) {
    .services-container-mob-view{
      width: 40%;
      margin: 15px;
    }
  }

  @media screen and (max-width:540px){
    .new-insu-btn-want-makes{
      text-align: center;
      font-size: 18px;
    }
  }