
@media screen and (max-width:912px){
    .local-seo-mobile-img{
        align-self: center;
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (max-width:767px){
    .seo-new-tools-list-container-item{
        margin-right: 0px;
    }
}