.business-pre-img{
    width: 40%;
}
.ppt-head{
    width: 700px;
    text-align: center;
}
.card-w{
    margin: 8px;
}
.bp-design-route-logos{
    height: 50px;
}

@media screen and (max-width:912px){
    .business-mob-pre-img{
        width: 100%;
    }

    .ppt-head-mob{
        width: 90%;
    }
    
}

@media screen and (max-width:767px){
    .business-mobile-para-n{
        width: 90% ;
    }
    .ppt-head-mob{
        font-size: 25px;
        line-height: 35px;
    }
}

@media screen and (max-width:539px){
    .mobile-logo-desi-con-bp{
        flex-direction: column;
    }
    .bp-design-route-logos{
        align-self: center;
        margin-bottom: 15px;
    }
    .bp-head{
        align-self: center;
    }
    .bp-types{
        text-align: center;
        font-size: 25px;
        line-height: 35px;
    }
}


