.payment-policy-route-heading-para-1{
    font-family: sans-serif;
    font-size: 18px;
    padding-bottom: 20px;
    width: 85%;
}

.privacy-policy-h{
    padding-top: 17px;
    font-size: 22px;
}