.php-development-route-p-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.php-developer-rote-head{
    font-family: sans-serif;
    font-size: 40px;
    width: 50%;
    line-height: 43px;
    padding-bottom: 15px;
    text-align: center;
}

.php-developer-rote-para{
    font-family: sans-serif;
    font-size: 18px;
    width: 50%;
    text-align: center;
}

.php-development-route-p-con-1{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
}
.php-development-route-p-con-1-div{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    width: 90%;
}
.php-development-route-p-con-img{
    height: 60px;
    width: 60px;
    margin-top: 15px;
}

.heading-phpcustom-app{
    font-family: sans-serif;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.para-phpcustom-app{
    font-family: sans-serif;
    font-size: 17px;

}

.php-development-route-p-con-1-inner{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    border: 3px solid aliceblue;
}

.php-developer-rote-head-follow{
    font-family: sans-serif;
    font-size: 18px;
    padding-bottom: 10px;
}

.image-php-rote-develop{
background-color: #0000aa;
border-radius: 5px;
flex-shrink: 0;
width: 60px;
height: 60px;
display: flex;
margin: 5px;
margin-right: 15px;
align-items: center;
justify-content: center;
}

.image-php-rote-develop-img{
    height: 45px;
   
    width: 45px;
}

.requirement-gath-heading{
    font-size: 20px;
  padding-bottom: 8px;
    line-height: 28px;

    font-family: sans-serif;
}

.requirement-gath-para{
    font-family: sans-serif;
    font-size: 17px;
    padding-top: 0px;
    margin-top: 0px;
}

.requirement-gath-para-span{
    font-weight: bold;
}

.php-development-dd-con{
    display: flex;
    width: 45%;
    margin: 10px;
}

.para-parner-types-p{
    font-family: sans-serif;
    font-size: 17px;
    padding-top: 10px;
    text-align: center;
}
.container-php-partn-it{
    height: fit-content;
    padding: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.larvel-head-part{
    font-size: 20px;
    font-family: sans-serif;
}

.para-parner-types-p-2{
    padding-top: 0px;
}
.container-php-partn-it:hover{
    .larvel-head-part{
        color: #ffffff;
    }
    .para-parner-types-p-2{
        color: #ffffff;
    }
}

.un-order-list-items-php{
    order: -1;
}

.paragh-php{
    color: #ffffff;
}

.php-why-choose-us-con{
    min-height: fit-content !important;
}

.faq-question-php-route{
    font-family: sans-serif;
    font-size: 17px;
    color: #ffffff;
    line-height: 25px;
    font-weight: bold;
}

.faq-question-php-route-con{
    background-image: linear-gradient(to right,rgb(89, 89, 253),#0006b1,#0013de,rgb(26, 26, 202));
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.faq-question-php-route-con-main{
    width: 46%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    margin: 10px;
}

.fa-arrow-circle-up{
    color: #ffffff;
    font-size: 28px;
}

.faq-question-php-route-ans{
    font-family: sans-serif;
    font-size: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
}
.php-developer-rote-head-follow{
    width: 90%;
    text-align: center;
}

@media screen and (min-width:768px) and (max-width:991px){
    .php-development-route-p-con-1-inner{
        width: 45%;
    }
    .php-developer-rote-head-r-r{
        font-size: 30px;
        line-height: 35px;
    }
    .partner-insu-head-c-reed{
        font-size: 25px;
    }
    .partner-route-about-head{
        font-size: 30px;
        text-align: center;
        line-height: 37px;
    }
}


@media screen and (max-width:767px){
    .php-development-route-p-con-1-inner{
        width: 100%;
    }
    .php-developer-rote-head-r-r{
        font-size: 20px;
        line-height: 30px;
    }
    .php-development-route-p-con-1-res-n{
        width: 95%;
    }
    .php-developer-rote-head-r-r{
        width: 100%;
        line-height: 26px;
    }
    .php-developer-rote-para{
        width: 90%;
    }
    .php-development-dd-con{
        width: 90%;
        margin-top: 10px;
    }
    .partner-insu-head-c-reed{
        font-size: 20px;
        line-height: 25px;
    }
    .partner-insu-head-c-faq{
        font-size: 22px;
        line-height: 26px;
    }
    .faq-question-php-route-con-main{
        width: 100%;
    }
    .php-development-route-p-con-1-div{
        width: 95%;
    }
    .faq-question-php-route{
        width: 80%;
        font-size: 18px;
    }
    .fa-arrow-circle-up{
        font-size: 25px;
    }
}