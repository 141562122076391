.technologies-we-use-route-con-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
.our-technologies-stack-main-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.technologies-we-use-route-con{
    width: 88%;
}

.our-technologies-stack{
    font-size: 40px;
    font-weight: bold;
    color: #313979;
    padding-bottom: 10px;
}

.our-technologies-stack-para{
    font-size: 18px;
    width: 650px; 
}
.bg-btn2-m {
    background: #1b32b6;
    background: -moz-linear-gradient(left,#2a08c4 0,#7d23f3 100%);
    background: -webkit-linear-gradient(left,#2a08c4 0,#b02cd6 100%);
    background: linear-gradient(to right,#2a08c4 0,#3009dd 100%);
    -webkit-box-shadow: 0 10px 15px 0 rgba(41, 31, 184, 0.2);
    box-shadow: 0 10px 15px 0 rgba(59, 21, 228, 0.2)
}

.our-technologies-stack-btn{
    background-color: transparent;
    border: none;
    margin-top: 30px;
    cursor: pointer;
}

.our-tech-img{
    width: 30%;
}
.technologies-we-use-design-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    
}

.design-des-para{
    width: 700px;
}

.technologies-we-use-design-con-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    width: 100vw;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705475877/reqb4uqdclv8lljouutp.jpg");
}
.technologies-we-use-design-con-2{
    width: 100vw;
    background-image: url("https://res.cloudinary.com/dlj7gj1po/image/upload/v1705475877/reqb4uqdclv8lljouutp.jpg");
}
.tech-use-img-container {
    padding: 1em;
    transition: 0.5s;
    margin: 2px;
    margin-bottom: 8px;
    height: 250px;
    width: 310px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .tech-use-img-container:hover {
    margin-top: -15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .tech-use-img-container-main{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
  }
  
  .tech-use-img{
    height: 34px;
  }

  .design-des-para-2{
    width: 850px;
    font-size: 17px;
  }

  .tech-use-img-container-2{
    width: 32%;
  }
  .technologies-we-use-design-con-main-2{
    padding-top: 0px;
  }

  .tech-use-img-2{
    height: 50px;
  }

  .tech-use-img-3{
    height: 55px;
  }


  .tech-use-img-container-hosting{
    width: 40%;
  }
  .tech-use-img-4{
    height: 70px;
  }

  .hosting-div-platform-con{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
.tech-use-img-container-hosting{
  margin-right: 15px;
  width: 40%;
}
.mobile-cont-side-1{
  position: fixed;
}
  @media  screen and (max-width:912px) {
    .our-technologies-stack-main-container{
      flex-direction: column;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 50px;
    }
    .our-tech-img{
      width: 70%;
      margin-top: 20px;
    }
    .mobile-view-cutting-head{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 95%;
    }
    .design-des-para-2{
      width: 90%;
    }
    .poster-des-para{
      width: 90%;
    }
    
  }


  @media screen and (max-width:767px){
    .tech-use-img-container-hosting{
      width: 80%;
    }
    .mobile-div-head-design{
      font-size: 30px;
      line-height: 40px;
      text-align: center;
    }
    .cloud-mobile-img{
      width: 80%;
    }
    .our-technologies-stack-para{
      width: 100%;
      text-align: center;
      padding-top: 15px;
    }
    .mobile-vi-tech-con-div{
      width: 95%;
    }
    .c-panel-h-panel{
]
      width: 90%;
      margin-right: 0px;
    }
  }


  .c-panel-h-panel-img{
    height: 50px;
  }