.dropdown-cont-list{
    color: black;
    font-size: 18px;
    background-color: aqua;
    text-decoration: none;
    padding-top: 15px;
}
.dropdown-main-container{
padding-left: 0px;
list-style-type: none;
}
.cot{
    display: flex;
    align-items: center;
}

.services-submenu{
    z-index: 1;
    background-color: #ffffff;
    padding:20px;
    margin-top: -20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.servi-s-2{
    left: 0px;
}
.services-submenu-2{
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.about-drop{
    line-height: 30px;
}

.new-div-dp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-dr-m{
    top:90px;
}
.service-we-development-dd{
    font-family: sans-serif;
    color: #313979;
    font-size: 17px;
    border-bottom: 2px solid #313979;
    line-height: 30px;
}

.service-we-development-dd-1{
    list-style-type: none;
    padding-left: 25px;
    padding-top: 5px;
}

.about-drop-2{
    font-size: 15px;
    margin-top: 4px;
    line-height: 23px;
}
.about-drop-2:hover{
    color: #000080;
    font-weight: 600;
}
.web-log{
    height: 45px;
}

.web-log-container{
    display: flex;
    align-items: flex-start;
}

.div-web-log-container{
    width: 25vw;
}

.hr-nav-n-line{
    min-height: 200px;
    max-height: 800px;
    border: 1px solid #313979;
    margin-left: 10px;
}

.about-drop-2-list{
    margin-bottom: 10px;
}