.graphic-port-images-p {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 20px;
    transition: .5s;
    cursor: pointer;
    -webkit-mask: 
      linear-gradient(135deg,#000c 40%,#000,#000c 60%)
      100% 100%/250% 250%;
  }
  .graphic-port-images-p:hover {
    -webkit-mask-position: 0 0;
  }
  