.about-us-component-heading{
    font-family: sans-serif;
    font-size: 35px;
    font-weight: bold;
    padding-bottom: 10px;
}
.about-us-component-paragraph{
    font-family: sans-serif;
    font-size: 17px;
    padding-bottom: 30px;
    max-width: 600px;
    line-height: 30px;
}
.about-us-component-button{
    height: 50px;
    color: #ffffff;
    background-color: blue;
    font-family: sans-serif;
    width: 170px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
}

.about-component-img{
    width: 40%;
}
.about-component-main-details-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.about-component-main-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 50px;
}

@media screen and (max-width:912px){
    .about-component-main-details-1{
        flex-direction: column;
    }
    .about-us-component-heading{
        font-size: 27px;
    }
    .about-us-component-button{
        font-size: 17px;
    }
    .about-component-img{
        width: 90%;
        margin-top: 35px;
    }
}