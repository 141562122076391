.our-clients-route-container-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.our-clients-route-container-inner{
    width: 60%;
}
.our-clients-route-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.our-clients-route-heading{
    font-size: 30px;
    color: #313979;
    line-height: 40px;
    padding-bottom: 15px;
}
.our-clients-route-para{
    font-family: sans-serif;
    font-size: 18px;
}
.our-client-route-image{
    width: 30%;
}

.our-clients-route-head{
    font-size: 40px;
    color: #313979;
    line-height: 40px;
    padding-bottom: 10px;
}

.our-clients-route-para-2{
    font-size: 20px;
    font-family: sans-serif;
    padding-bottom: 30px;
    padding-top: 10px;
}
.our-clients-route-list-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.our-clients-route-list-container{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.our-clients-route-list-container-item{
    height: 190px;
    width: 190px;
    height: 200px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
}

.our-clients-route-list-image{
    height: 100px;
}
.see-more-button{
    height: 50px;
    width: 130px;
    background-image: linear-gradient(to right,rgb(86, 86, 184),blue);
    color: #ffffff;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    border: none;
    margin-top: 25px;
    border-radius: 10px;
}

.see-more-button:hover {
    box-shadow: 0 0 10px blue;
}

@media screen and (max-width:915px){
    .our-clients-route-container{
        flex-direction: column;
        justify-content: center;
    }
    .our-clients-route-container-inner{
        width: 100%;
    }
    .our-client-route-image{
        width: 50%;
        margin-top: 15px;
    }
    .our-clients-route-heading{
        text-align: center;
        font-size: 25px;
        line-height: 35px;
    }
    .our-clients-route-para-2{
        width: 90%;
        text-align: center;
    }
}

@media screen and (max-width:443px){
    .our-clients-route-list-container-item{
        height: 150px;
        width: 150px;
    }
    .our-clients-route-list-image{
        height: 70px;
    }
}

