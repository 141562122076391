.industries-we-work-head{
	font-family: sans-serif;
	font-size: 16px;
	color: #e60072;
	padding-bottom: 15px;
    font-weight: 400;
}
.helping-business-para{
	font-family: sans-serif;
	font-size: 43px;
	font-weight: bold;
	color: #002157;
	padding-bottom: 15px;
	max-width: 420px;
	flex-shrink: 1;
	letter-spacing: 1px;
	line-height: 58px;
}
.successfully-delivered-para{
	font-size: 20px;
	flex-shrink: 1;
	padding-top: 15px;
	line-height: 32px;
	letter-spacing: 1px;
}





.industries-main-cont-work-main{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	background: linear-gradient(to bottom,#fbf9ed 0,#fbeaf8 100%);
}

.industries-main-cont-work{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.industries-cont-left-cont{
	width: 40%;
	margin-left: 30px;
}

.industries-work-right-cont{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 70%;
	justify-content: center;

}
.cont-indus{
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	width: 22%;
	cursor: pointer;
	height: 190px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-top-left-radius: 70px;
	border-bottom-right-radius: 70px;
	border: none;
	margin-right: 15px;
	margin-bottom: 30px;
    background-color: #ffffff;
    outline: none;

}
.cont-indus:hover{
	box-shadow: rgb(74, 130, 194) 0px 10px 30px 0px, blue 0px 0px 0px 1px;
	border: none;
}
.image-indus{
	height: 40px;
}

.cont-industries-cont{
	background-color: lightgray;
	width: 80px;
	height: 80px;
	border-radius: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.social-media-para{
	font-family: sans-serif;
	font-size: 15px;
	padding-top: 10px;
    text-align: center;
}

@media screen and (max-width:767px) {
	.industries-main-cont-work{
		flex-direction: column;
	}
	.industries-cont-left-cont{
		width: 100%;
		margin-left: 0px;
	}
	.industries-main-cont-work-main{
		padding: 20px;
	}
	.industries-we-work-head{
		text-align: center;
	}
	.helping-business-para{
		font-size: 27px;
		line-height: 40px;
		text-align: center;
	}
	.successfully-delivered-para{
		font-size: 17px;
	}
	.cont-indus{
		width: 40%;
	}
	.industries-work-right-cont{
		width: 100%;
		justify-content: center;
		align-items: center;
		margin-top: 25px;
	}
	.social-media-para{
		width: 90%;
		line-height: 25px;
	}
}

@media screen and (min-width:768px) and (max-width:991px){
	.industries-main-cont-work{
		flex-direction: column;
	}
	.industries-cont-left-cont{
		width: 100%;
		margin-left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.industries-main-cont-work-main{
		padding: 20px;
	}
	.industries-we-work-head{
		text-align: center;
	}
	.helping-business-para{
		font-size: 27px;
		line-height: 40px;
		text-align: center;
	}

	.cont-indus{
		width: 30%;
	}
	.industries-work-right-cont{
		width: 80%;
		justify-content: center;
		align-items: center;
		margin-top: 25px;
	}
	.successfully-delivered-para{
		text-align: center;
	}
}