*{
  text-decoration: none;
}

.nav-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80px;
  }

  .nav-main-container-2{
    background-color: #ffffff;
    position: fixed;
    top:0px;
    bottom: -150px;
    margin-bottom: -150px;
    z-index: 99;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .nav-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
  }
  .co{
    display: flex;

  }
  .list-container-navbar{
    display: flex;
    align-items: center;
    padding-left: 0px;
    list-style-type: none;
  }
  .nav-logo-container{
    display: flex;
    align-items: center;
  }
  
  .particle-nav-container{
    height: 50px;
  }
  .kreeti-technologies-heading{
    font-family: sans-serif;
    font-size: 23px;
    color: #ffffff;
    padding-left: 10px;
    font-weight: normal;
  }
  
  .nav-link-list-item{
    font-family: sans-serif;
    font-size: 15px;
    color: #ffffff;
    padding-right: 25px;
    transition: 0.3s all ease-out;
    cursor: pointer;

  }
  .nav-link-list-item:hover{
    color:rgb(65, 125, 216);
  }
  .nav-link-list-item-mbn:hover{
    color:rgb(65, 125, 216);
  }
.portfolio-nav{
  padding-top: 8px;
}



.nav-link-about-us{
  color: #ffffff;
  
}

.nav-scroll-about{
  color: black !important;
}
.nav-not-scroll{
  color: #ffffff;
}
.serv-ss-s{
  height: 80px;
}
.desktop-nav-v{
  display: none;
}
@media screen and (min-width:913px) {
  .desktop-nav-v{
    display: block;
  }
}


.mobile-nav-logo-img{
  height: 38px;
  
}
.mobile-nav-head-logo{
  font-size: 18px;
  font-family: sans-serif;
  padding-left: 10px;
}

.mobile-nav-logo-container{
  display: flex;
  align-items: center;
}

.mobile-nav-main-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100vw;
  background-color: #ffffff;
  padding-left: 10px;
  position: fixed;
  z-index: 11;
}

.mobile-cont-side-2{
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*background-color: rgba(700, 225, 15, 0.55);*/
    background-color: #ffffff;
    width: 290px;
    min-height: 100vh;
    backdrop-filter: blur(5px);
    margin: 0;
    z-index: 999;
    position: absolute;
    transition: all 0.3s ease-in-out;
}
.mobile-cont-side-1{
  position: fixed;
  top: 0px;
  z-index: 12;
  
}


@media screen and (min-width:280px) and (max-width:300px) {
  .mobile-cont-side-2{
    width:220px
  }
}
.mobile-cont-side-1{
  display: flex;
  justify-content: space-between;
}


@media screen and (min-width:1025px)  {
  .mobile-cont-side-1{
    display: none;
  }
}

.mobile-nav-it{
  font-family: sans-serif;
  font-size: 18px;
  color: #6866b1;
}
.mobile-nav-it-list-item{
  margin: 13px;
}
.mobile-close-head{
  font-family: sans-serif;
  font-size: 19px;
  color: #6866b1;
}
.mobile-drop-down-it{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-nav-it-click{
  font-family: sans-serif;
  font-size: 16px;
  color: #6866b1;
  padding-top: 10px;
}
.mobile-close-head-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: aliceblue;
}

.mobile-nav-it-list-container{
  list-style-type: none;
}

.mobile-about-click-ul{
  padding-left:10px;
  background-color: aliceblue;
  padding-top: 2px;
  padding-bottom: 2px;
}


.mobile-nav-it-click-web{
  font-size: 15px;
  color: #6866b1;
  font-weight: bold;
}

.side-bar-in{
  color: #6866b1;
  font-size: 20px;
}

.sidebar-icons-main-d{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.sidebar-icons-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

@media screen and (max-width:1024px){
  .nav-main-container-2{
    display: none;
  }
}