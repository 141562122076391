.what-we-do-component-container-main{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.what-we-do-component-container-about{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.what-we-do-component-container-details{
    width: 50%;
}

.what-we-do-img{
    width: 50%;
}
.what-we-do-component-heading{
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.what-we-do-component-paragraph{
    font-family: sans-serif;
    font-size: 17px;
    line-height: 30px;
    padding-bottom: 15px;
    padding-top: 10px;
}
.horizontal-line-what-we-do{
    border: 1px dashed grey;
}

.hr-pg:hover{
    color: blueviolet;
}

@media screen and (max-width:991px){
    .what-we-do-component-container-about{
        flex-direction: column;
    }
    .what-we-do-img{
        width: 90%;
        order: 1;
        margin-top: 30px;
    }
    .what-we-do-component-container-details{
        width: 90%;
    }
    .what-we-do-component-heading-mob{
        font-size: 27px;
    }
    .what-we-do-component-paragraph{
        font-size: 16px;
    }
}