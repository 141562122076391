.schedule-metting-route-main-con{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding-top: 50px;
background-color: #f5f5f5;
}
@media screen and (max-width:914px){
    .schedule-metting-route-main-con{
        padding-top: 150px;
    }
}
.schedule-metting-route-heading{
    font-family: sans-serif;
    font-size: 40px;
    text-align: center;
    width: 55%;
    padding-bottom: 12px;
}

.schedule-metting-route-paragraph{
    font-family: sans-serif;
    font-size: 18px;
    color: #777;
    text-align: center;
}

.phone-call-click-no{
    font-family: sans-serif;
    font-size: 18px;
    color: #007bff;
}

.schedule-metting-route-paragraph-con{
    display: flex;
    align-items: center;
}

@media screen and (max-width:520px){
    .schedule-metting-route-heading{
        font-size: 25px;
        width: 90%;
        line-height: 35px;
    }
    .schedule-metting-route-paragraph{
        padding-bottom: 20px;
        padding-top: 5px;
        width: 95%;
    }
}