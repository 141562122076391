.project {
    margin: 50px 50px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.project hr {
    color: #771387;
    width: 400px;
    margin: 10px auto;
}

.card {
    width: 450px;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.project h2 {
    font-weight: bold;
    color: #138781;
}

.cardNotifyBadge {
    position: absolute;
    left: -10px;
    top: -20px;
    background-color: #f29f67;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
}

.project .card img {
    height: 200px;
    width: 100%;
}

.cardDetailBadge {
    background-color: #e0b50f;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
    margin: 10px;
}
.cardImageOverly {
    font-size: 20px;
    margin: 10px;
}
.cardImageOverly span {
    display: inline-block;
    margin-left: 5px;
}

.project .card:hover {
    background-color: white;
    border-radius: 4px;
}

.adBtn {
    text-transform: uppercase;
    width: 150px;
    height: 35px;
    display: block;
    text-align: center;
    /* background-color: #138781; */
    color: white;
    border-radius: 80px;
    font-size: 16px;
    line-height: 35px;
    text-decoration: none;
    margin: 20px auto 20px auto;
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(
        to right,
        rgb(89, 89, 253),
        #0006b1,
        #0013de,
        rgb(26, 26, 202)
    );
}

.adBtn:hover {
    background-color: white;
    color: rgb(89, 89, 253);
    background: transparent;
    border: 1px solid rgb(89, 89, 253);
    transition: all 0.2s ease;
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
        rgba(0, 0, 0, 0.09) 0px 32px 16px; */
}

@media (max-width: 500px) {
    .card {
        width: 100%;
    }
    .project .card img {
        height: 125px;
    }
}
