.nav-container-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    background-color: #3d4162;
    height: 50px;
  }
  .telephone-img{
height: 35px;
  }
  @media  screen and (max-width:1024px) {
    .nav-container-head{
      display: none;
    }
  }
  .google-logo-rating-container {
    display: flex;
    align-items: center;
  }
  
  .google-img {
    height: 70px;
    margin-right: 6px;
  }
  .star-img {
    height: 20px;
  }
  .rating-paragraph {
    color: #ffffff;
    font-size: 18px;
    padding-right: 15px;
  }
  .call-back-container {
    display: flex;
    align-items: center;
    background-color: #5b6083;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    height: 45px;
    width: 250px;
  }
  .profile-img {
    height: 35px;
    width: 35px;
  }
  
  .request-call-para {
    color: #ffffff;
    font-family: 'Roboto';
    font-size: 18px;
    padding-left: 10px;
    font-weight: bold;
    font-family: sans-serif;
    animation: animate  1.5s linear infinite; 
  }
  @keyframes animate { 
    0% { 
        opacity: 0; 
    } 

    50% { 
        opacity: 10; 
    } 

    100% { 
        opacity: 0; 
    } 
} 
  .request-call-container {
    display: flex;
    align-items: center;
  }

  .number-glow-span{
    font-weight: bold;
  }
  .talk-to-space{
    font-size: 16px;
  }