.branding-benefits{
    font-family: sans-serif;
    color: #313979;
    font-size: 35px;
    padding-bottom: 15px;
}

.branding-benefits-para{
    font-family: sans-serif;
    font-size: 17px;
    line-height: 25px;
    padding-bottom: 10px;
}
.branding-benefits-list-item{
    font-size: 17px;
    color: #313979;
    margin-top: 15px;
    
}

.list-transform-brand{
    margin-top: 15px;
}

.transform-right-list-container-items-2{
    margin-top: 10px;
}
.branding-benefits-2{
    font-size: 23px;
    padding-bottom: 3px;
}

.complete-brand-con{
    width: 90%;
    display: flex;
    justify-content: space-between;

}
.complete-brand-con-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.complete-brand-benefits-con{
    width: 60%;
}
.new-anima-con{
    width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.some-of-prev-mob-v{
    text-align: center;
}
.some-of-prev-mob-v-para{
    width: 90%;
}
@media screen and (max-width:912px){
    .complete-des-mob-v{
        width: 100%;
        margin-top: 20px;
    }
    .complete-brand-con{
        flex-direction: column;
        justify-content: center;
    }
    .complete-brand-benefits-con{
        width: 100%;
    }
}


@media screen and (max-width:540px){
    .branding-benefits{
        font-size: 25px;
        text-align: center;
    }
    .card-mobile-view-ani{
        width: 90%;
        margin: 10px;
    }
    .some-of-prev-mob-v{
        width: 90%;
        font-size: 25px;
        text-align: center;
    }
    .some-of-prev-mob-v-para{
        font-size: 18px;
    }
    .branding-benefits{
        line-height: 35px;
        padding-top: 10px;
    }
}

@media screen and (min-width:541px) and (max-width:767px){
    .card-mobile-view-ani{
        width: 40%;
        margin: 10px;
    }
    .card-mobile-view-ani-1{
        width: 100%;
    }
}